import Http from './api'
import { ICreateTask, IUpdateTask } from '../shared/interfaces'

export const getTasks = () => {
  return Http.get('/tasks')
}

export const createTask = (task: ICreateTask) => {
  return Http.post('/tasks', task)
}

export const updateTask = (taskId: number, updateBody: IUpdateTask) => {
  return Http.patch(`/tasks/${taskId}`, updateBody)
}
