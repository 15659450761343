import { AnyAction } from 'redux'

import { IPaginationResponse, IUser, IUserDetails, IUsersState } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IUsersState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  details: undefined,
  traders: undefined
}

const usersReducer = (state = initialState, action: AnyAction): IUsersState => {
  switch (action.type) {
    case actionTypes.GET_USERS_SUCCESS: {
      const response: IPaginationResponse<IUser> = action.response?.data
      if (!response) return state
      const totalCount =
        Object.keys(action.payload.filter).length && state.list.totalCount
          ? state.list.totalCount
          : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_TRADERS__SUCCESS: {
      const traders = action.response?.data
      return {
        ...state,
        traders
      }
    }

    case actionTypes.UNASSIGN_CUSTOMER_FROM_USER_SUCCESS:
    case actionTypes.GET_USER_DETAILS_SUCCESS: {
      const details: IUserDetails = action.response?.data
      return { ...state, details }
    }

    default:
      return state
  }
}

export default usersReducer
