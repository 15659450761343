import styled from 'styled-components'
import { ExtendedColorsType } from '@otion-core/sandy'

const Table = styled.table<{ background?: ExtendedColorsType }>`
  background: ${props => (props.background ? props.theme.colors[props.background].default : 'transparent')};
  border: 1px solid #b8bebd;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 15px;
  overflow: hidden;

  thead {
    th {
      background: rgb(0 10 20 / 2%);
      font-size: 14px;
      font-weight: 600;
    }
  }

  tbody {
    td {
      font-size: 14px;
    }
  }

  /* Apply a border to the right of all but the last column */
  th:not(:last-child) {
    border-right: 1px solid #b8bebd;
  }

  &.all-borders td:not(:last-child) {
    border-right: 1px solid #b8bebd;
  }

  &.selectable > tbody > tr {
    &:hover {
      background: rgb(0 10 20 / 4%);
      cursor: pointer;
    }
  }

  /* Apply a border to the bottom of all but the last row */
  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr:not(:last-child) > th,
  > tbody > tr:not(:last-child) > td,
  > tfoot > tr:not(:last-child) > th,
  > tfoot > tr:not(:last-child) > td,
  > tr:not(:last-child) > td,
  > tr:not(:last-child) > th,
  > thead:not(:last-child),
  > tbody:not(:last-child),
  > tfoot:not(:last-child) {
    border-bottom: 1px solid #b8bebd;
  }
`

export { Table }
