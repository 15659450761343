import { IPaginationResponse, IRateItem, TableSort, IRatesFilter } from 'src/shared/interfaces'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'

export const getRates = (filter: IRatesFilter, sort: TableSort<IRateItem> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter,
    searchBy: ['tariff'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IRateItem>>('/rates', payload)
}

export const getPriceDetail = (id: number) => {
  return Http.get<IRateItem>(`/rates/${id}`)
}

export const updatePriceDetail = (id: number, payload: IRateItem) => {
  return Http.patch<IRateItem>(`/rates/${id}`, payload)
}
