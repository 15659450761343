import React, { ReactNode, forwardRef } from 'react'
import cx from 'classnames'
import { Fade } from '@material-ui/core'
import { BaseComponent } from '@otion-core/sandy'

import * as S from './styles'

interface ExpansionPanelDetailsProps extends BaseComponent {
  /** Details content */
  children: ReactNode
  /** Expanded */
  expanded?: boolean
}
export const ExpansionPanelDetails = forwardRef<HTMLDivElement, ExpansionPanelDetailsProps>(
  function ExpansionPanelDetails({ children, expanded, className, ...rest }, ref) {
    const expandedClassName = expanded ? 'expanded' : 'collapsed'

    const rootClass = cx(className, expandedClassName)

    return (
      <Fade timeout={300} in={expanded}>
        <S.ContentWrapper className={rootClass} ref={ref} {...rest}>
          {children}
        </S.ContentWrapper>
      </Fade>
    )
  }
)

ExpansionPanelDetails.defaultProps = {}

ExpansionPanelDetails.displayName = 'ExpansionPanelDetails'
export default ExpansionPanelDetails
