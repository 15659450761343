import {
  IEicFilter,
  TableSort,
  IEic,
  IEicDetails,
  PromiseAction,
  CreateEicPayload,
  ISetTerminationDateEicPayload
} from '../../shared/interfaces'
import * as EicApi from '../../apis/eic.api'

export const GET_EIC_TRIGGERED = 'GET_EIC_TRIGGERED'
export const GET_EIC_SUCCESS = 'GET_EIC_SUCCESS'
export const GET_EIC_FAILURE = 'GET_EIC_FAILURE'

export function getEic(filter: IEicFilter, sort: TableSort<IEic> | undefined, page: number, size = 10): PromiseAction {
  const promise = EicApi.getAllEic(filter, sort, page, size)
  return {
    onRequest: GET_EIC_TRIGGERED,
    onSuccess: GET_EIC_SUCCESS,
    onFailure: GET_EIC_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

export const GET_EIC_DETAIL_TRIGGERED = 'GET_EIC_DETAIL_TRIGGERED'
export const GET_EIC_DETAIL_SUCCESS = 'GET_EIC_DETAIL_SUCCESS'
export const GET_EIC_DETAIL_FAILURE = 'GET_EIC_DETAIL_FAILURE'

let getEicDetailController: AbortController
export function getEicDetail(eicId: number | string): PromiseAction {
  if (getEicDetailController) getEicDetailController.abort()
  getEicDetailController = new AbortController()
  const promise = EicApi.getEicDetail(eicId, getEicDetailController.signal)
  return {
    onRequest: GET_EIC_DETAIL_TRIGGERED,
    onSuccess: GET_EIC_DETAIL_SUCCESS,
    onFailure: GET_EIC_DETAIL_FAILURE,
    promise,
    payload: { eicId }
  }
}

export const GET_EIC_MONTHLY_VOLUMES_TRIGGERED = 'GET_EIC_MONTHLY_VOLUMES_TRIGGERED'
export const GET_EIC_MONTHLY_VOLUMES_SUCCESS = 'GET_EIC_MONTHLY_VOLUMES_SUCCESS'
export const GET_EIC_MONTHLY_VOLUMES_FAILURE = 'GET_EIC_MONTHLY_VOLUMES_FAILURE'

export function getEicMonthlyValues(eicId: number | string): PromiseAction {
  const promise = EicApi.getEicMonthlyValues(eicId)
  return {
    onRequest: GET_EIC_MONTHLY_VOLUMES_TRIGGERED,
    onSuccess: GET_EIC_MONTHLY_VOLUMES_SUCCESS,
    onFailure: GET_EIC_MONTHLY_VOLUMES_FAILURE,
    promise,
    payload: { eicId }
  }
}

export const UPDATE_EIC_DETAIL_TRIGGERED = 'UPDATE_EIC_DETAIL_TRIGGERED'
export const UPDATE_EIC_DETAIL_SUCCESS = 'UPDATE_EIC_DETAIL_SUCCESS'
export const UPDATE_EIC_DETAIL_FAILURE = 'UPDATE_EIC_DETAIL_FAILURE'

export function updateEicDetail(eicId: number | string, values: Partial<IEicDetails>): PromiseAction {
  const promise = EicApi.updateEicDetail(eicId, values)
  return {
    onRequest: UPDATE_EIC_DETAIL_TRIGGERED,
    onSuccess: UPDATE_EIC_DETAIL_SUCCESS,
    onFailure: UPDATE_EIC_DETAIL_FAILURE,
    promise,
    payload: { eicId, values }
  }
}

export const CREATE_EIC_TRIGGERED = 'CREATE_EIC_TRIGGERED'
export const CREATE_EIC_SUCCESS = 'CREATE_EIC_SUCCESS'
export const CREATE_EIC_FAILURE = 'CREATE_EIC_FAILURE'

export function createEicDetail(values: CreateEicPayload): PromiseAction {
  const promise = EicApi.createEic(values)
  return {
    onRequest: CREATE_EIC_TRIGGERED,
    onSuccess: CREATE_EIC_SUCCESS,
    onFailure: CREATE_EIC_FAILURE,
    promise,
    payload: { values }
  }
}

export const GET_EIC_ACTIONS_TRIGGERED = 'GET_EIC_ACTIONS_TRIGGERED'
export const GET_EIC_ACTIONS_SUCCESS = 'GET_EIC_ACTIONS_SUCCESS'
export const GET_EIC_ACTIONS_FAILURE = 'GET_EIC_ACTIONS_FAILURE'

export const getEicActions = () => {
  const promise = EicApi.getEicActions()
  return {
    onRequest: GET_EIC_ACTIONS_TRIGGERED,
    onSuccess: GET_EIC_ACTIONS_SUCCESS,
    onFailure: GET_EIC_ACTIONS_FAILURE,
    promise
  }
}

export const GET_AVAILABLE_EIC_LIST_TRIGGERED = 'GET_AVAILABLE_EIC_LIST_TRIGGERED'
export const GET_AVAILABLE_EIC_LIST_SUCCESS = 'GET_AVAILABLE_EIC_LIST_SUCCESS'
export const GET_AVAILABLE_EIC_LIST_FAILURE = 'GET_AVAILABLE_EIC_LIST_FAILURE'

export const getAvailableEicList = (fromDate: string): PromiseAction => {
  const promise = EicApi.getAvailableEicList(fromDate)
  return {
    onRequest: GET_AVAILABLE_EIC_LIST_TRIGGERED,
    onSuccess: GET_AVAILABLE_EIC_LIST_SUCCESS,
    onFailure: GET_AVAILABLE_EIC_LIST_FAILURE,
    promise
  }
}

export const POST_REPAIR_EIC_MZM_TRIGGERED = 'POST_REPAIR_EIC_MZM_TRIGGERED'
export const POST_REPAIR_EIC_MZM_SUCCESS = 'POST_REPAIR_EIC_MZM_SUCCESS'
export const POST_REPAIR_EIC_MZM_FAILURE = 'POST_REPAIR_EIC_MZM_FAILURE'

export const postRepairMzm = (id: number): PromiseAction => {
  const promise = EicApi.repairMzm(id)
  return {
    onRequest: POST_REPAIR_EIC_MZM_TRIGGERED,
    onSuccess: POST_REPAIR_EIC_MZM_SUCCESS,
    onFailure: POST_REPAIR_EIC_MZM_FAILURE,
    promise
  }
}

// Export bank accounts list in xls
export const EXPORT_EICS_TRIGGERED = 'EXPORT_EICS_TRIGGERED'
export const EXPORT_EICS_SUCCESS = 'EXPORT_EICS_SUCCESS'
export const EXPORT_EICS_FAILURE = 'EXPORT_EICS_FAILURE'

export function exportEics(filter: IEicFilter): PromiseAction {
  const promise = EicApi.exportEics(filter)
  return {
    onRequest: EXPORT_EICS_TRIGGERED,
    onSuccess: EXPORT_EICS_SUCCESS,
    onFailure: EXPORT_EICS_FAILURE,
    promise
  }
}

// Set delivery end date for eic
export const SET_DELIVERY_END_EIC_TRIGGERED = 'SET_DELIVERY_END_EIC_TRIGGERED'
export const SET_DELIVERY_END_EIC_SUCCESS = 'SET_DELIVERY_END_EIC_SUCCESS'
export const SET_DELIVERY_END_EIC_FAILURE = 'SET_DELIVERY_END_EIC_FAILURE'
export function setTerminationDateEic(eicId: number, values: ISetTerminationDateEicPayload): PromiseAction {
  const promise = EicApi.setTerminationDateEic(eicId, values)
  return {
    onRequest: SET_DELIVERY_END_EIC_TRIGGERED,
    onSuccess: SET_DELIVERY_END_EIC_SUCCESS,
    onFailure: SET_DELIVERY_END_EIC_FAILURE,
    promise
  }
}
