import {
  TableSort,
  IConsumption,
  IConsumptionFilter,
  IPagination,
  IConsumptionForEic,
  IGetConsumptionByEicPayload
} from 'src/shared/interfaces'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'

export const getAllConsumption = (
  filter: IConsumptionFilter,
  sort: TableSort<IConsumption> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      'eic.delivery_start': [filter.delivery_start_date_from, filter.delivery_start_date_to],
      'eic.status': filter.status
    },
    searchBy: ['eic'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPagination<IConsumption>>('/consumptions', payload)
}

export const getConsumptionByEic = (id: string, params: IGetConsumptionByEicPayload) => {
  return Http.get<IConsumptionForEic[]>(`/consumptions/${id}`, params)
}

export const getReloadOkteConsumption = () => {
  return Http.get<unknown>('/scheduler/fetch-consumptions')
}
