import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from '../reducers'
import apiMiddleware from '../middleware/api-middleware'

const enhancers = []
const middlewares = [thunk, apiMiddleware]

if (process.env.REACT_APP_ENV_NAME === 'local') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { devToolsExtension } = window as any
  if (typeof devToolsExtension === 'function') {
    // eslint-disable-next-line fp/no-mutating-methods
    enhancers.push(devToolsExtension())
  }
}

const store = createStore(rootReducer, compose(applyMiddleware(...middlewares), ...enhancers))

export default store
