import { IContract } from './contracts.interface'

export interface ICustomer {
  id: number
  customer?: string
  company_address_street?: string
  company_address_zip?: string
  company_address_city?: string
  correspondence_address_street?: string
  correspondence_address_zip?: string
  correspondence_address_city?: string
  company_id?: string
  tax_id?: string
  vat_id?: string
  status?: CustomerStatusEnum
  excise_duty_payer?: boolean
  vat_id_for_excise_duty?: string
  type_of_customer?: string
  customer_role_ids?: number[]
  user_id?: number
  emails?: string[]
}

// eslint-disable-next-line no-shadow
export enum CustomerStatusEnum {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT'
}

export type ICustomerSimple = Pick<ICustomer, 'id' | 'customer' | 'emails' | 'status'>

export interface ISimpleCustomerListItem {
  id: number
  customer: string
}

export interface ICustomerDetails {
  average_number_of_days_past_due: string
  bank: string
  bank_account: string
  bic_swift: string
  commercial_register_number: string
  commercial_register_section: string
  company_address_city: string
  company_address_street: string
  company_address_zip: string
  company_id: string
  contracts?: IContract[]
  contracts_value: string
  correspondence_address_city: string
  correspondence_address_street: string
  correspondence_address_zip: string
  created_at: string
  created_on: string
  credit_limit: string
  cribis_index: string
  cribis_traffic_light: string
  customer: string
  customer_role_ids: number[]
  delivery_period_end: string
  delivery_period_start: string
  department: string
  department_contact_person: string
  department_e_mail: string
  department_phone: string
  department_position: string
  district_court_commercial_register: string
  district_office: string
  emails?: string[]
  economicly_related_group: string
  error_status: string
  excise_duty_payer: boolean
  financial_situation: string
  free_credit_limit: string
  group_traffic_light: string
  iban: string
  id: number
  industry_evaluation: string
  internal_index: string
  legal_representatives_contact_person: string
  legal_representatives_e_mail: string
  legal_representatives_phone: string
  legal_representatives_position: string
  level_of_risk: string
  margin_earned: string
  payment_discipline_index: string
  probability_of_bankruptcy: string
  risk_assessment_average_number_of_days_past_due: string
  status: CustomerStatusEnum
  tax_id: string
  total_margin: string
  trade_register_number: string
  type_of_customer: string
  user_id?: number
  vat_id: string
  vat_id_for_excise_duty: string
}

export interface ICustomerRole {
  id: number
  name: string
}

export interface ICustomersFilter {
  id?: number
  type_of_customer?: string
  customer_role_ids?: number[]
  search?: string
  status?: CustomerStatusEnum
  without_account?: boolean
}

export interface ICreateCustomerPayload {
  company_address_city?: string
  company_address_street?: string
  company_address_zip?: string
  company_id?: string
  customer_role_ids?: number[]
  correspondence_address_city?: string
  correspondence_address_street?: string
  correspondence_address_zip?: string
  customer?: string
  emails?: string | string[]
  excise_duty_payer?: string
  iban?: string
  swift?: string
  tax_id?: string
  vat_id?: string
  vat_id_for_excise_duty?: string
}
