import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import localization from './localization'

const language = localStorage.getItem('language') || 'en'
const defaultNS = 'common'

i18n.use(initReactI18next).init({
  defaultNS,
  resources: localization,
  lng: language,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
})

export { defaultNS, localization as resources }

export const changeLanguage = (code: string): void => {
  i18n.changeLanguage(code)
  localStorage.setItem('language', code)
}

export default i18n
