import styled from 'styled-components'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import OwlCarousel from 'react-owl-carousel3'
import { Toolbox as SToolbox } from '@otion-core/sandy/build/components'
import { getBPHelpers } from '@otion-core/sandy'

const Carousel = styled(OwlCarousel)`
  display: grid !important;

  .owl-stage-outer {
    width: calc(100% + 1.5rem);
    margin: -0.75rem;
    padding: 0.75rem 0.75rem 1.5rem;
  }
`

const Toolbox = styled(SToolbox)<{ active?: boolean; disabled?: boolean }>`
  cursor: pointer;
  margin: auto;
  height: 180px !important;
  width: 180px !important;

  &:not(.no-svg-stroke) {
    svg {
      fill: ${({ iconColor, theme }) => theme.colors[iconColor as never].default} !important;
      stroke: ${({ iconColor, theme }) => theme.colors[iconColor as never].default} !important;
    }
  }

  h4 {
    text-align: center !important;
  }
  ${props =>
    props.disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
  `}

  ${props => getBPHelpers(props.theme.breakpoints).screens('small')} {
    height: 155px !important;
    width: 155px !important;
  }
`

export { Carousel, Toolbox }
