import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { BillStatusEnum } from 'src/shared/interfaces'

interface BillStatusProps {
  value?: string
}

const BillStatus = (props: BillStatusProps) => {
  const { t } = useTranslation()
  if (!props.value) return null

  let color

  switch (props.value) {
    case BillStatusEnum.UNVERIFIED:
      color = '#ff3b30'
      break
    case BillStatusEnum.NEW:
      color = '#e6e6e6'
      break
    case BillStatusEnum.CHECKED:
      color = '#8abd25'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`BILL_STATUS.${props.value}` as never)}</span>
    </Container>
  )
}

BillStatus.displayName = 'BillStatus'
export default BillStatus
