import styled from 'styled-components'

const ContentWrapper = styled.div`
  background: white;
  cursor: pointer;
  width: 100%;
  position: relative;
  transition: all ease 0.3s;
  padding: 20px 30px;

  .expand-icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.expanded {
    .expand-icon > svg {
      transition: all ease 0.3s;
      transform: rotate(180deg);
    }
  }
`

export { ContentWrapper }
