import React from 'react'

import { Layout, LayoutProps } from '../'

interface Props {
  layout?: React.FC<LayoutProps>
  component: React.ComponentType
  isAnonym?: boolean
  isClientRoute?: boolean
  isTraderRoute?: boolean
}

const LayoutRoute = ({
  layout = Layout,
  isAnonym = false,
  isClientRoute = false,
  isTraderRoute = false,
  component
}: Props) => React.createElement(layout, { isAnonym, isClientRoute, isTraderRoute }, React.createElement(component))

LayoutRoute.displayName = 'LayoutRoute'
export default LayoutRoute
