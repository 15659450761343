import React, { ReactNode, useCallback } from 'react'
import { Container, Paper, Typography, Icon, useNotifications as useNotificationsSandy } from '@otion-core/sandy'

import guidGenerator from '../../lib/guidGenerator'
import { IHttpError } from '../interfaces'

const useNotifications = () => {
  const { showCustomNotification, closeNotification } = useNotificationsSandy()

  const showNotification = useCallback(
    (content: ReactNode, isError = false, timeout?: number) => {
      const key = guidGenerator()
      setTimeout(() => closeNotification(key), timeout || (isError ? 15000 : 4000))
      return showCustomNotification(
        <Container onClick={() => closeNotification(key)} style={{ cursor: 'pointer' }}>
          <Paper background={isError ? 'red' : 'green.light'}>
            <Container padded='small' left='small' right='large' flex alignItems='center'>
              <Container right='small'>
                <Icon name='quiet' color={isError ? 'white' : 'black'} size='large' />
              </Container>
              <Typography color={isError ? 'white' : 'black'} weight='semibold' as='span'>
                {content}
              </Typography>
            </Container>
          </Paper>
        </Container>,
        {
          vertical: 'top',
          horizontal: 'center'
        },
        { persist: true, key }
      )
    },
    [showCustomNotification, closeNotification]
  )

  const showError = useCallback(
    (content: ReactNode, errorResponse?: IHttpError) => {
      const parsedResponse = Array.isArray(errorResponse?.message)
        ? errorResponse?.message.join('. ')
        : errorResponse?.message
      const finalContent = parsedResponse || content
      return showNotification(finalContent, true)
    },
    [showNotification]
  )
  const showSuccess = useCallback((content: ReactNode, timeout?: number) => showNotification(content, false, timeout), [
    showNotification
  ])

  return { showError, showSuccess }
}

export default useNotifications
