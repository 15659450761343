import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import store from './store'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Provider = ({ children }: any) => {
  return <ReduxProvider store={store}>{children}</ReduxProvider>
}

Provider.displayName = 'Provider'
export default Provider
