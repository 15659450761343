import { AnyAction } from 'redux'

import { IPaginationResponse, IConsumption, IConsumptionsState, IConsumptionForEic } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IConsumptionsState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  details: undefined
}

const spotPricesReducer = (state = initialState, action: AnyAction): IConsumptionsState => {
  switch (action.type) {
    case actionTypes.GET_CONSUMPTIONS_SUCCESS: {
      const response: IPaginationResponse<IConsumption> = action.response?.data
      const totalCount = Object.keys(action.payload.filter).length ? state.list.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_CONSUMPTION_BY_EIC_SUCCESS: {
      const details: IConsumptionForEic[] = action.response?.data
      return { ...state, details }
    }

    default:
      return state
  }
}

export default spotPricesReducer
