import Http from './api'
import {
  IAssignCustomerPayload,
  ICreateUserPayload,
  IPaginationResponse,
  IUpdateUserPayload,
  IUser,
  IUserDetails,
  IUsersFilter,
  TableSort
} from '../shared/interfaces'
import { generatePaginationPayload } from '../lib/paginationPayload'

export const createUser = (user: ICreateUserPayload) => {
  return Http.post('/users/create', user)
}

export const getAllUsers = (filter: IUsersFilter, sort: TableSort<IUser> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter: {
      is_active: filter.isActive,
      role: filter.role
    },
    searchBy: ['email', 'first_name', 'last_name'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IUser>>('/users', payload)
}

export const updateUser = (id: number, updateBody: IUpdateUserPayload) => {
  return Http.patch(`/users/${id}`, updateBody)
}

export const deleteUser = (id: number) => {
  return Http.delete(`/users/${id}`)
}

export const getUsersByActiveStatus = (isActive: boolean) => {
  return Http.get(`/users`, { is_active: isActive })
}

export const getUserDetails = (id: string) => {
  return Http.get<IUserDetails>(`/users/${id}`)
}

export const assignCustomerToUser = (payload: IAssignCustomerPayload) => {
  return Http.patch<IUserDetails>(`/users/assign-customer/${payload.user_id}`, { customer_id: payload.customer_id })
}

export const removeUserById = (id: string) => {
  return Http.delete(`/users/${id}`)
}

export const getTraders = () => {
  return Http.get<IUser[]>('/users/traders')
}

export const unassignCustomerToUser = (payload: IAssignCustomerPayload) => {
  return Http.patch<IUserDetails>(`/users/unassign-customer/${payload.user_id}`, { customer_id: payload.customer_id })
}
