import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
  }
`

export default GlobalStyle
