import styled from 'styled-components'
import { getBPHelpers } from '@otion-core/sandy'

const ConnectionsScrollBox = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc(180px);
  grid-column-gap: 20px;
  margin: -1.5rem;
  overflow-x: auto;
  padding: 1.5rem;

  @media ${props => getBPHelpers(props.theme.breakpoints).AppBreakpoints.mediaQueries.mobileFirst['extra-large']} {
    max-width: calc(100vw - 6.25rem);
  }
`

export default ConnectionsScrollBox
