import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { getBPHelpers, OnSelectChangeEvent, Select } from '@otion-core/sandy'

import { user$ } from 'src/redux/selectors'
import { LogoIcon, LogoTextIcon } from 'src/assets/icons'
import { changeLanguage } from 'src/i18n'

import * as S from './styles'

const languages = [
  {
    value: 'en',
    text: 'EN'
  },
  {
    value: 'sk',
    text: 'SK'
  }
]

const HeaderRightAdornment = () => {
  const themeContext = useContext(ThemeContext)
  const BPHelpers = getBPHelpers(themeContext.breakpoints)
  const isSmall = BPHelpers.useBreakpoint('small')

  const user = useSelector(user$)

  const [language, setLanguage] = useState<string>(languages[0].value)

  useEffect(() => {
    const currentLanguage = localStorage.getItem('language') || 'sk'
    setLanguage(currentLanguage)
  }, [])

  const handleChangeLanguage = (event: OnSelectChangeEvent) => {
    const newLanguage = event.target.value as string
    setLanguage(newLanguage)
    changeLanguage(newLanguage)
  }

  return (
    <S.ContentWrapper>
      {isSmall ? null : (
        <React.Fragment>
          <Select
            value={language}
            onChange={handleChangeLanguage}
            options={languages}
            style={{ height: '36px', width: '100px', marginRight: '30px' }}
            placeholder=''
          />
          <S.UserName>
            {user ? (
              <React.Fragment>
                {user.first_name}
                <br />
                {user.last_name}
              </React.Fragment>
            ) : null}
          </S.UserName>
        </React.Fragment>
      )}

      <LogoIcon style={isSmall ? undefined : { marginRight: 12 }} />
      {isSmall ? null : <LogoTextIcon />}
    </S.ContentWrapper>
  )
}

HeaderRightAdornment.defaultProps = {}

HeaderRightAdornment.displayName = 'HeaderRightAdornment'
export default HeaderRightAdornment
