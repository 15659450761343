import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { INVOICE_STATUS } from 'src/shared/interfaces'
import { ColorDot } from 'src/components/commonStyled'

interface InvoiceStatusProps {
  value?: number | string
}

const InvoiceStatus = (props: InvoiceStatusProps) => {
  const { t } = useTranslation()

  if (!props.value) return null

  const status = [
    { id: INVOICE_STATUS.DRAFT, name: 'DRAFT' },
    { id: INVOICE_STATUS.ISSUED, name: 'ISSUED' },
    { id: INVOICE_STATUS.SENT, name: 'SENT' },
    { id: INVOICE_STATUS.DELIVERED, name: 'DELIVERED' },
    { id: INVOICE_STATUS.READ, name: 'READ' },
    { id: INVOICE_STATUS.NOT_DELIVERED, name: 'NOT_DELIVERED' },
    { id: INVOICE_STATUS.CANCELED, name: 'CANCELED' }
  ].find(invoiceStatus => invoiceStatus.id === Number(props.value))
  let color

  switch (Number(status?.id)) {
    case INVOICE_STATUS.DRAFT:
      color = '#e6e6e6'
      break
    case INVOICE_STATUS.ISSUED:
      color = '#ffa300'
      break
    case INVOICE_STATUS.SENT:
      color = '#f3d163'
      break
    case INVOICE_STATUS.DELIVERED:
      color = '#8abd25'
      break
    case INVOICE_STATUS.READ:
      color = '#8abd25'
      break
    case INVOICE_STATUS.NOT_DELIVERED:
      color = '#ff3b30'
      break
    case INVOICE_STATUS.CANCELED:
      color = '#424242'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`INVOICE_STATUS.${status?.name}` as never)}</span>
    </Container>
  )
}

InvoiceStatus.displayName = 'InvoiceStatus'
export default InvoiceStatus
