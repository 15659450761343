import moment from 'moment'

import {
  EicStatusEnum,
  IClientInvoicesFilter,
  IConsumption,
  IConsumptionFilter,
  IConsumptionForEic,
  IEic,
  IEicDetails,
  IEicFilter,
  IGetConsumptionByEicPayload,
  IGetSpotPricesPayload,
  IInvoice,
  IPagination,
  IPaginationResponse,
  ISpotPrice,
  TableSort
} from 'src/shared/interfaces'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'
import { DATE_FORMAT_DB } from '../shared/constants'

export const getClientsEic = (filter: IEicFilter, sort: TableSort<IEic> | undefined, page: number, size = 10) => {
  const isNewEics = filter.status === EicStatusEnum.NEW
  const isTerminatedEics = filter.status === EicStatusEnum.TERMINATED_DELIVERY
  const payload = generatePaginationPayload({
    filter: {
      status: !isNewEics && !isTerminatedEics ? filter.status : undefined,
      new_eics: isNewEics,
      terminated_eics: isTerminatedEics
    },
    searchBy: ['eic'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IEic>>('/clients/eic', payload)
}

export const getSpotPrices = (params: IGetSpotPricesPayload) => {
  return Http.get<ISpotPrice>('/clients/spot-prices', params)
}

export const getClientsConsumption = (
  filter: IConsumptionFilter,
  sort: TableSort<IConsumption> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      'eic.delivery_start': [filter.delivery_start_date_from, filter.delivery_start_date_to],
      'eic.status': filter.status
    },
    searchBy: ['eic'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPagination<IConsumption>>('/clients/consumptions', payload)
}

export const getClientsInvoices = (
  filter: IClientInvoicesFilter,
  sort: TableSort<IInvoice> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_period_start: [
        filter.delivery_period_start_from
          ? moment(filter.delivery_period_start_from).format(DATE_FORMAT_DB)
          : undefined,
        filter.delivery_period_start_to ? moment(filter.delivery_period_start_to).format(DATE_FORMAT_DB) : undefined
      ],
      type_of_invoice: filter.type_of_invoice,
      status: filter.status
    },
    searchBy: ['variable_symbol'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IInvoice>>('/clients/invoices', payload)
}

export const getClientPdfInvoice = (id: number) => {
  return Http.getFile<unknown>(`/clients/invoices/${id}/pdf`)
}

export const getConsumptionByEicByClient = (id: string, params: IGetConsumptionByEicPayload) => {
  return Http.get<IConsumptionForEic[]>(`/clients/consumptions/${id}`, params)
}

export const getEicDetailByClient = (id: number | string, signal?: AbortSignal) => {
  return Http.get<IEicDetails>(`/clients/eic/${id}`, {}, signal)
}
