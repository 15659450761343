import styled from 'styled-components'
import { Paper, Link, Typography } from '@otion-core/sandy'

const FooterPaper = styled(Paper)`
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  min-height: 500px;
`

const BigLink = styled(Link)`
  color: ${props => props.theme.colors.white.default};
  display: block;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-decoration-color: ${props => props.theme.colors.white.light};
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;

  &:hover {
    text-decoration-color: ${props => props.theme.colors.green.light};
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
  }
`

const LabelText = styled(Typography)`
  color: ${props => props.theme.colors.white.default} !important;
  letter-spacing: 0.05rem;
`

const BankInfo = styled(Typography)`
  margin-top: 8px;

  span {
    color: white;
    font: inherit !important;
    font-size: 13px !important;
  }

  > span {
    display: block;
    line-height: 20px !important;

    > span {
      display: inline-block;
      min-width: 60px;
    }
  }
`

export { FooterPaper, BigLink, LabelText, BankInfo }
