import React, { useRef, useState, useEffect } from 'react'
import { Container, Button, Typography, ColorsType } from '@otion-core/sandy'
import { useTranslation } from 'react-i18next'

import { ArrowDownIcon } from '../../assets/icons'
import { IPagination, TableSort, IColumn } from '../../shared/interfaces'
import Loader from '../Loader'
import Table from './Table'
import * as S from './styles'

const SHOW_ALL_LIMIT = 500

interface DataTableProps {
  /* Array of table columns */
  columns?: IColumn[]
  /* Array of data objects */
  data?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  /* Url for item details */
  detailUrlPrefix?: string
  /* Use this row field to open details (defaults to "id") */
  navigateByField?: string
  /* Whether table data is loading */
  isLoading?: boolean
  /* Change page handler */
  onChangePage?: (page: number, pageSize?: number) => void
  /* Sort data handler */
  onChangeSort?: (sort: TableSort<any>) => void // eslint-disable-line @typescript-eslint/no-explicit-any
  /* On select rows handler */
  onChangeSelected?: (selected: string[]) => void
  /* Initial sort order value */
  initialSort?: TableSort<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  /* Pagination object from BE response */
  pagination?: IPagination<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  /* Accent color of elements */
  color?: ColorsType
}

const DataTable = (props: DataTableProps) => {
  const {
    columns = [],
    data = [],
    detailUrlPrefix,
    navigateByField,
    onChangePage,
    onChangeSort,
    onChangeSelected,
    pagination,
    isLoading,
    initialSort,
    color
  } = props

  const { t } = useTranslation()
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [hideLeftScrollButton, toggleLeftScrollButton] = useState(true)
  const [hideRightScrollButton, toggleRightScrollButton] = useState(false)
  const [scrollPos, setScrollPos] = useState(0)
  const timer = useRef<ReturnType<typeof setTimeout>>(null)

  useEffect(() => {
    toggleRightScrollButton(false)
  }, [data])

  const scrollTableHandle = (direction: 'left' | 'right', hold = 1) => {
    if (tableContainerRef !== null && tableContainerRef?.current) {
      const scrollWidth = tableContainerRef.current.scrollWidth
      const singleOffset = 200 + (Math.min(hold, 3) - 1) * 50 // every hold iteration moves scroll further
      let nextScrollPos = direction === 'left' ? scrollPos - singleOffset * hold : scrollPos + singleOffset * hold

      if (nextScrollPos <= 0) {
        nextScrollPos = 0
        toggleLeftScrollButton(true)
      } else {
        toggleLeftScrollButton(false)
      }
      if (nextScrollPos >= scrollWidth - tableContainerRef.current.clientWidth + 20) {
        nextScrollPos = scrollWidth - tableContainerRef.current.clientWidth + 20
        toggleRightScrollButton(true)
      } else {
        toggleRightScrollButton(false)
      }
      setScrollPos(nextScrollPos)
      tableContainerRef.current.scrollTo(nextScrollPos, 0)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      timer.current = setTimeout(() => scrollTableHandle(direction, hold + 1), 250)
    }
  }

  const handleMouseDown = (direction: 'left' | 'right') => () => {
    scrollTableHandle(direction)
  }

  const handleMouseUp = () => {
    if (timer.current) clearTimeout(timer.current)
  }

  const currentPage = pagination?.currentPage || 0

  const handlePrevPage = () => {
    if (onChangePage && currentPage > 1) onChangePage(currentPage - 1, pagination?.itemsPerPage)
  }

  const handleNextPage = () => {
    if (onChangePage) onChangePage(currentPage + 1, pagination?.itemsPerPage)
  }

  const handleShowAll = () => {
    if (onChangePage) onChangePage(1, Math.min(pagination?.totalItems || SHOW_ALL_LIMIT, SHOW_ALL_LIMIT))
  }

  return (
    <div style={{ flex: 1, width: 0, position: 'relative' }}>
      <S.TableWrapper>
        {isLoading && (
          <S.LoadingContainer>
            <Loader />
          </S.LoadingContainer>
        )}

        <S.TableScrollWrapper ref={tableContainerRef}>
          <Table
            columns={columns}
            data={data}
            detailUrlPrefix={detailUrlPrefix}
            navigateByField={navigateByField}
            pagination={pagination}
            initialSort={initialSort}
            onChangeSort={onChangeSort}
            onChangeSelected={onChangeSelected}
          />
        </S.TableScrollWrapper>

        {pagination ? (
          pagination.totalPages > 1 ? (
            <Container>
              <Container flex justifyContent='space-between' alignItems='center'>
                <Container>
                  <Button
                    size='xsmall'
                    onClick={handlePrevPage}
                    style={{ minWidth: 150, boxShadow: '0 4px 10px 0 rgba(0,0,0,.15)' }}
                    disabled={pagination.currentPage <= 1}
                    color={color}
                  >
                    <Typography size='medium' color='white' weight='bold'>
                      {t('BACK')}
                    </Typography>
                  </Button>
                </Container>
                <Container>
                  {pagination.currentPage} / {pagination.totalPages}
                </Container>
                <Container>
                  <Button
                    size='xsmall'
                    onClick={handleNextPage}
                    style={{ minWidth: 150, boxShadow: '0 4px 10px 0 rgba(0,0,0,.15)' }}
                    disabled={pagination.currentPage === pagination.totalPages}
                    color={color}
                  >
                    <Typography size='medium' color='white' weight='bold'>
                      {t('NEXT')}
                    </Typography>
                  </Button>
                </Container>
              </Container>
              <Container flex justifyContent='center'>
                {pagination?.itemsPerPage >= SHOW_ALL_LIMIT ? (
                  <Container top='medium'>
                    <Typography size='small' font='secondary' color='light'>
                      {t('SHOWING_MAXIMUM', { max: SHOW_ALL_LIMIT })}
                    </Typography>
                  </Container>
                ) : (
                  <Container onClick={handleShowAll} top='medium'>
                    <Typography size='medium' weight='semibold' underline='dashed' link color={color}>
                      {t('SHOW_ALL')}
                    </Typography>
                  </Container>
                )}
              </Container>
            </Container>
          ) : (
            <Container flex justifyContent='center'>
              <Typography color='light-grey'>
                {pagination.totalItems > 0 ? t('SHOWING_ROWS', { count: pagination.totalItems }) : t('EMPTY_ROWS')}
              </Typography>
            </Container>
          )
        ) : null}
      </S.TableWrapper>

      <S.ScrollButton
        variant='primary'
        color='white'
        fullWidth={false}
        stopPropagation
        arrow='left'
        onMouseDown={handleMouseDown('left')}
        onMouseUp={handleMouseUp}
        hide={hideLeftScrollButton ? 'true' : ''}
      >
        <ArrowDownIcon />
      </S.ScrollButton>

      <S.ScrollButton
        variant='primary'
        color='white'
        fullWidth={false}
        stopPropagation
        arrow='right'
        onMouseDown={handleMouseDown('right')}
        onMouseUp={handleMouseUp}
        hide={hideRightScrollButton ? 'true' : ''}
      >
        <ArrowDownIcon />
      </S.ScrollButton>
    </div>
  )
}

DataTable.displayName = 'DataTable'
export default DataTable
