import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { BaseLayout, IconNamesType } from '@otion-core/sandy'
import { MenuItemProps } from '@otion-core/sandy/build/components/MenuItem'

import { hasToken } from 'src/utils'

export interface LayoutProps {
  children?: ReactNode
  isAnonym: boolean
  isClientRoute: boolean
  isTraderRoute: boolean
}

const menu: MenuItemProps[] = [
  {
    title: 'Domov',
    icon: 'home' as IconNamesType,
    big: true,
    exact: true,
    to: '/'
  },
  {
    title: 'Upozornenia',
    icon: 'quiet' as IconNamesType,
    to: '/warnings'
  },
  {
    title: 'Nastavenia',
    icon: 'settings' as IconNamesType,
    to: '/settings'
  },
  {
    title: 'Odhlásiť sa',
    icon: 'logout' as IconNamesType,
    to: '/logout'
  }
]

const clientMenu: MenuItemProps[] = [
  {
    title: 'Domov',
    icon: 'home' as IconNamesType,
    big: true,
    exact: true,
    to: '/'
  },
  {
    title: 'Nastavenia',
    icon: 'settings' as IconNamesType,
    to: '/client/settings'
  },
  {
    title: 'Odhlásiť sa',
    icon: 'logout' as IconNamesType,
    to: '/logout'
  }
]

const traderMenu: MenuItemProps[] = [
  {
    title: 'Domov',
    icon: 'home' as IconNamesType,
    big: true,
    exact: true,
    to: '/'
  },
  {
    title: 'Nastavenia',
    icon: 'settings' as IconNamesType,
    to: '/trader/settings'
  },
  {
    title: 'Odhlásiť sa',
    icon: 'logout' as IconNamesType,
    to: '/logout'
  }
]

const Layout = ({ children, isAnonym, isClientRoute, isTraderRoute }: LayoutProps) => {
  if (!isAnonym) {
    if (!hasToken()) return <Navigate to='/login' />
  } else {
    if (hasToken()) return <Navigate to='/' />
  }

  const userMenu = isClientRoute ? clientMenu : isTraderRoute ? traderMenu : menu

  return (
    <BaseLayout defaultTitle='Home | Encare' titleTemplate='%s | Encare' menu={isAnonym ? undefined : userMenu}>
      {children}
    </BaseLayout>
  )
}

Layout.displayName = 'Layout'
export default Layout
