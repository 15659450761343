import {
  TableSort,
  PromiseAction,
  IUsersFilter,
  IUser,
  ICreateUserPayload,
  IAssignCustomerPayload
} from '../../shared/interfaces'
import * as UserApi from '../../apis/user.api'

export const GET_USERS_TRIGGERED = 'GET_USERS_TRIGGERED'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'

export const getUsers = (
  filter: IUsersFilter,
  sort: TableSort<IUser> | undefined,
  page: number,
  size = 10
): PromiseAction => {
  const promise = UserApi.getAllUsers(filter, sort, page, size)
  return {
    onRequest: GET_USERS_TRIGGERED,
    onSuccess: GET_USERS_SUCCESS,
    onFailure: GET_USERS_FAILURE,
    promise,
    payload: { filter, page, size }
  }
}

export const CREATE_USER_TRIGGERED = 'CREATE_USER_TRIGGERED'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

export const createUser = (payload: ICreateUserPayload): PromiseAction => {
  const promise = UserApi.createUser(payload)
  return {
    onRequest: CREATE_USER_TRIGGERED,
    onSuccess: CREATE_USER_SUCCESS,
    onFailure: CREATE_USER_FAILURE,
    promise
  }
}

export const GET_USER_DETAILS_TRIGGERED = 'GET_USER_DETAILS_TRIGGERED'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE'

export const getUserDetails = (id: string): PromiseAction => {
  const promise = UserApi.getUserDetails(id)
  return {
    onRequest: GET_USER_DETAILS_TRIGGERED,
    onSuccess: GET_USER_DETAILS_SUCCESS,
    onFailure: GET_USER_DETAILS_FAILURE,
    promise
  }
}

export const ASSIGN_CUSTOMER_TO_USER_TRIGGERED = 'ASSIGN_CUSTOMER_TO_USER_TRIGGERED'
export const ASSIGN_CUSTOMER_TO_USER_SUCCESS = 'ASSIGN_CUSTOMER_TO_USER_SUCCESS'
export const ASSIGN_CUSTOMER_TO_USER_FAILURE = 'ASSIGN_CUSTOMER_TO_USER_FAILURE'

export const assignCustomerToUser = (payload: IAssignCustomerPayload): PromiseAction => {
  const promise = UserApi.assignCustomerToUser(payload)
  return {
    onRequest: ASSIGN_CUSTOMER_TO_USER_TRIGGERED,
    onSuccess: ASSIGN_CUSTOMER_TO_USER_SUCCESS,
    onFailure: ASSIGN_CUSTOMER_TO_USER_FAILURE,
    promise
  }
}

export const REMOVE_USER_TRIGGERED = 'REMOVE_USER_TRIGGERED'
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE'

export const removeUserById = (id: string) => {
  const promise = UserApi.removeUserById(id)
  return {
    onRequest: REMOVE_USER_TRIGGERED,
    onSuccess: REMOVE_USER_SUCCESS,
    onFailure: REMOVE_USER_FAILURE,
    promise
  }
}

export const GET_TRADERS_TRIGGERED = 'GET_TRADERS__TRIGGERED'
export const GET_TRADERS__SUCCESS = 'GET_TRADERS__SUCCESS'
export const GET_TRADERS__FAILURE = 'GET_TRADERS__FAILURE'

export const getTraders = () => {
  const promise = UserApi.getTraders()
  return {
    onRequest: GET_TRADERS_TRIGGERED,
    onSuccess: GET_TRADERS__SUCCESS,
    onFailure: GET_TRADERS__FAILURE,
    promise
  }
}

export const UNASSIGN_CUSTOMER_FROM_USER_TRIGGERED = 'UNASSIGN_CUSTOMER_FROM_USER_TRIGGERED'
export const UNASSIGN_CUSTOMER_FROM_USER_SUCCESS = 'UNASSIGN_CUSTOMER_FROM_USER_SUCCESS'
export const UNASSIGN_CUSTOMER_FROM_USER_FAILURE = 'UNASSIGN_CUSTOMER_FROM_USER_FAILURE'

export const unassignCustomerFromUser = (payload: IAssignCustomerPayload): PromiseAction => {
  const promise = UserApi.unassignCustomerToUser(payload)
  return {
    onRequest: UNASSIGN_CUSTOMER_FROM_USER_TRIGGERED,
    onSuccess: UNASSIGN_CUSTOMER_FROM_USER_SUCCESS,
    onFailure: UNASSIGN_CUSTOMER_FROM_USER_FAILURE,
    promise
  }
}
