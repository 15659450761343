import styled from 'styled-components'

const Wrapper = styled.div`
  .svg-path {
    clip-path: url('#lightning-path-clip');
    fill: none;
  }

  &.small {
    display: inline-block;
    height: 32px;
    width: 16px;

    .lightning-container {
      filter: none;
      transform-origin: 0 3px;
      transform: scale(0.15) !important;
    }
  }

  @keyframes full {
    0% {
      opacity: 0;
      stroke-dashoffset: 0;
    }

    /* 1 */
    3.33% {
      opacity: 0;
      stroke-width: 2;
      stroke-dashoffset: 0;
    }

    /* 2 */
    6.66% {
      opacity: 0;
      stroke-width: 2;
      stroke-dashoffset: 0;
    }

    /* 3 */
    9.99% {
      opacity: 0;
      stroke-width: 2;
      stroke-dashoffset: 0;
    }

    /* 4 */
    13.32% {
      opacity: 1;
      stroke-width: 2;
      stroke-dashoffset: 0;
    }

    /* 5 */
    16.65% {
      opacity: 1;
      stroke-width: 15;
      stroke-dashoffset: 0;
    }

    /* 6 */
    19.98% {
      opacity: 1;
      stroke-width: 15;
      stroke-dashoffset: 0;
    }

    /*  7 */
    23.31% {
      opacity: 1;
      stroke-width: 15;
      stroke-dashoffset: 0;
    }

    /* 8 */
    26.64% {
      opacity: 1;
      stroke-width: 15;
      stroke-dashoffset: 0;
    }

    /* 9 */
    29.97% {
      opacity: 1;
      stroke-width: 15;
      stroke-dashoffset: 0;
    }

    /* 10 */
    33.33% {
      opacity: 1;
      stroke-width: 15;
      stroke-dashoffset: 0;
    }

    /* 11 */
    36.66% {
      opacity: 1;
      stroke-width: 15;
      stroke-dashoffset: 0;
    }

    /* 12 */
    39.99% {
      opacity: 1;
      stroke-width: 15;
      stroke-dashoffset: 0;
    }

    /* 13 */
    43.32% {
      opacity: 1;
      stroke-width: 15;
      stroke-dashoffset: 0;
    }

    /* 14 */
    46.65% {
      opacity: 1;
      stroke-width: 15;
      stroke-dashoffset: 0;
    }

    /* 15 */
    49.98% {
      stroke-width: 7;
      stroke-dashoffset: 0;
    }

    /* 16 */
    53.31% {
      stroke-width: 6;
      stroke-dashoffset: 0;
    }

    /* 17 */
    56.64% {
      stroke-width: 5;
      stroke-dashoffset: 0;
    }

    /* 18 */
    59.97% {
      stroke-width: 4;
    }

    /* 19 */
    63.33% {
      stroke-width: 3;
    }

    /* 20 */
    66.66% {
      stroke-width: 2;
      opacity: 0.8;
    }

    /* 21 */
    69.99% {
      stroke-width: 1;
      opacity: 0.6;
    }

    /* 22 */
    73.32% {
      stroke-width: 1;
      opacity: 0.4;
    }

    /* 23 */
    76.55% {
      stroke-width: 1;
      opacity: 0.2;
    }

    /* 24 */
    79.98% {
      stroke-width: 1;
      opacity: 0;
    }

    /* 25 */
    83.31% {
      stroke-width: 1;
      opacity: 0;
    }

    /* 26 */
    86.64% {
      stroke-width: 1;
      opacity: 0;
    }

    /* 27 */
    89.97% {
      stroke-width: 1;
      opacity: 0;
    }

    /* 28 */
    93.33% {
      stroke-width: 1;
      opacity: 0;
    }

    /* 29 */
    96.66% {
      stroke-width: 1;
      opacity: 0;
    }

    /* 30 */
    100% {
      stroke-width: 1;
      opacity: 0;
      stroke-dashoffset: 102.9643;
    }
  }

  @keyframes scale {
    0% {
      transform: scale(0.6);
    }

    /* 4 */
    13.32% {
      transform: scale(0.6);
    }

    /* 5 */
    16.65% {
      transform: scale(0.75);
    }

    /*  7 */
    23.31% {
      transform: scale(0.6);
    }

    /* 8 */
    100% {
      transform: scale(0.6);
    }
  }
`

export { Wrapper }
