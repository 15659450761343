import Http from './api'
import {
  IChangePassword,
  IForgotPasswordPayload,
  ILoginPayload,
  IResetPasswordPayload,
  ISignInAsPayload
} from '../shared/interfaces'

export const login = (payload: ILoginPayload) => {
  return Http.post('/auth/login', { email: payload.email, password: payload.password })
}

export const signInAs = (payload: ISignInAsPayload) => {
  return Http.post('/auth/sign-in-as', { email: payload.email })
}

export const getMe = () => {
  return Http.get('/auth/me')
}

export const changePassword = (updateBody: IChangePassword) => {
  return Http.post('/auth/password', updateBody)
}

export const forgotPassword = (payload: IForgotPasswordPayload) => {
  return Http.post('/auth/forgot-password', { email: payload.email })
}

export const resetPassword = (payload: IResetPasswordPayload) => {
  return Http.post('/auth/new-password', { token: payload.token, password: payload.password })
}

export const checkResetToken = (token: string) => {
  return Http.post('/auth/check-token', { token })
}
