import { AnyAction } from 'redux'

import {
  IProformaInvoiceDetails,
  IPaginationResponse,
  IProformaInvoice,
  IProformaInvoicesState
} from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IProformaInvoicesState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  details: undefined
}

const proformaInvoicesReducer = (state = initialState, action: AnyAction): IProformaInvoicesState => {
  switch (action.type) {
    case actionTypes.GET_ALL_PROFORMA_INVOICES_SUCCESS: {
      const response: IPaginationResponse<IProformaInvoice> = action.response?.data
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length ? state.list.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_PROFORMA_INVOICE_DETAIL_SUCCESS: {
      const details: IProformaInvoiceDetails = action.response?.data
      return { ...state, details }
    }

    default:
      return state
  }
}

export default proformaInvoicesReducer
