import React from 'react'
import { BaseComponent, ExtendedColorsType } from '@otion-core/sandy'

import * as S from './styles'

interface TableProps extends BaseComponent {
  children?: React.ReactNode
  background?: ExtendedColorsType
  selectable?: boolean
  allBorders?: boolean
}

const Table = ({ children, background, selectable, allBorders, ...rest }: TableProps) => {
  const className = [rest.className, allBorders ? 'all-borders' : '', selectable ? 'selectable' : '']
    .filter(Boolean)
    .join(' ')
  return (
    <S.Table
      width='100%'
      cellPadding='10px'
      border={1}
      cellSpacing={0}
      background={background}
      className={className}
      {...rest}
    >
      {children}
    </S.Table>
  )
}

Table.displayName = 'Table'
export default Table
