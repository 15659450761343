import { AnyAction } from 'redux'

import { IPaginationResponse, IPriceListState, IRateItem } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IPriceListState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  details: undefined
}

const priceListReducer = (state = initialState, action: AnyAction): IPriceListState => {
  switch (action.type) {
    case actionTypes.GET_RATES_SUCCESS: {
      const response: IPaginationResponse<IRateItem> = action.response?.data
      if (!response) return state
      const totalCount =
        action.payload?.filter && Object.keys(action.payload.filter).length
          ? state.list.totalCount
          : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_PRICE_DETAIL_SUCCESS: {
      const details: IRateItem = action.response?.data
      return { ...state, details }
    }

    default:
      return state
  }
}

export default priceListReducer
