import { AnyAction } from 'redux'

import { IAction, IActionsState, IActionStatus, IPaginationResponse } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IActionsState = {
  statuses: [],
  electricityList: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    }
  }
}

const actionsReducer = (state = initialState, action: AnyAction): IActionsState => {
  switch (action.type) {
    case actionTypes.GET_ACTION_STATUSES_SUCCESS: {
      const statuses: IActionStatus[] = action.response?.data
      return { ...state, statuses }
    }

    case actionTypes.GET_ELECTRICITY_ACTIONS_SUCCESS: {
      const response: IPaginationResponse<IAction> = action.response?.data

      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length
        ? state.electricityList.totalCount
        : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        electricityList: { totalCount, data }
      }
    }

    default:
      return state
  }
}

export default actionsReducer
