import styled from 'styled-components'

const ContentWrapper = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 30px 30px;

  &.expanded {
    visibility: visible;
  }

  &.collapsed {
    height: 0;
    visibility: hidden;
    padding: 0 !important;
  }
`

export { ContentWrapper }
