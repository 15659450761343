import { AnyAction } from 'redux'

import { ISpotPricesState } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: ISpotPricesState = {
  spotPrices: []
}

const spotPricesReducer = (state = initialState, action: AnyAction): ISpotPricesState => {
  switch (action.type) {
    case actionTypes.GET_SPOT_PRICES_TRIGGERED: {
      return { ...state, spotPrices: initialState.spotPrices }
    }
    case actionTypes.GET_SPOT_PRICES_SUCCESS: {
      return { ...state, spotPrices: action.response?.data }
    }

    default:
      return state
  }
}

export default spotPricesReducer
