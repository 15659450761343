/* eslint-disable max-params */
import {
  IAction,
  IGetActionsParams,
  ICreateActionRequest,
  IPaginationResponse,
  TableSort,
  IActionFilter,
  EIC_ACTION,
  ICreateActionChangeOfParametersRequest,
  ICreateActionHistoricalDataRequest,
  ICreateActionChangeOfSupplierRequest
} from '../shared/interfaces'
import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'

// Get statuses
export const getActionStatuses = () => {
  return Http.get<IPaginationResponse<IAction>>('/actions/statuses')
}

// Get actions
export const getElectricityActions = (
  type: EIC_ACTION,
  filter: IActionFilter,
  sort: TableSort<IAction> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {},
    searchBy: ['eic'],
    search: filter.search,
    sort,
    page,
    size
  })
  let actionType = ''
  switch (type) {
    case EIC_ACTION.MOVE_IN:
      actionType = 'move-in'
      break
    case EIC_ACTION.MOVE_OUT:
      actionType = 'move-out'
      break
    case EIC_ACTION.CHANGE_OF_SUPPLIER:
      actionType = 'change-of-supplier'
      break
    default:
      throw new Error('Invalid action type')
  }
  return Http.post<IPaginationResponse<IAction>>(`/actions/${actionType}`, payload)
}

export const getMoveInActions = (params: IGetActionsParams) => {
  const payload = generatePaginationPayload(params)
  return Http.post<IPaginationResponse<IAction>>('/actions/move-in', payload)
}

export const getMoveOutActions = (params: IGetActionsParams) => {
  const payload = generatePaginationPayload(params)
  return Http.post<IPaginationResponse<IAction>>('/actions/move-out', payload)
}

export const getChangeOfParametersAction = (params: IGetActionsParams) => {
  const payload = generatePaginationPayload(params)
  return Http.post<IPaginationResponse<IAction>>('/actions/change-of-parameters', payload)
}

export const getDpTransferAction = (params: IGetActionsParams) => {
  const payload = generatePaginationPayload(params)
  return Http.post<IPaginationResponse<IAction>>('/actions/dp-transfer', payload)
}

// Create actions
export const createProcessEicAction = (payload: ICreateActionRequest) => {
  return Http.post('/actions/process-eic/create', payload)
}

export const createChangeOfSupplierAction = (payload: ICreateActionChangeOfSupplierRequest) => {
  return Http.post('/actions/change-of-supplier/create', payload)
}

export const createMoveInAction = (payload: ICreateActionRequest) => {
  return Http.post('/actions/move-in/create', payload)
}

export const createMoveOutAction = (payload: ICreateActionRequest) => {
  return Http.post('/actions/move-out/create', payload)
}

export const createDpTransferAction = (payload: ICreateActionRequest) => {
  return Http.post('/actions/dp-transfer/create', payload)
}

export const createHistoricalDataAction = (payload: ICreateActionHistoricalDataRequest) => {
  return Http.post('/actions/historical-data/create', payload)
}

export const createChangeOfParametersAction = (payload: ICreateActionChangeOfParametersRequest) => {
  return Http.post('/actions/change-of-parameters/create', payload)
}
