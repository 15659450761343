import React, { MouseEvent, useState } from 'react'
import { Button, Container, Grid, Typography } from '@otion-core/sandy'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import * as S from './styles'
import { EicIcon, PlusIcon } from '../../../assets/icons'
import { ColorDot, Error, ConnectionsContainer } from '../../commonStyled'
import { createTask } from '../../../apis/task.api'
import { user$ } from '../../../redux/selectors'
import { AddConnectionModal } from '../../Modal'
import ConnectionItem from '../../ConnectionItem'

interface AddTaskModalProps {
  open: boolean
  onClose: () => void
}

const initialValues = {
  title: '',
  description: ''
}

const TaskSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Task name is required.')
})

const AddTaskModal = ({ open, onClose }: AddTaskModalProps) => {
  const { t } = useTranslation()
  const user = useSelector(user$)
  const connections: any = []
  const [openAddConnectionModal, setOpenAddConnectionModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [connectionIds, setConnectionIds] = useState<number[]>([])

  const handleOpenAddConnectionModal = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
    setOpenAddConnectionModal(true)
  }

  const formik = useFormik({
    validationSchema: TaskSchema,
    initialValues,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true)
      createTask({ ...values, connections: connectionIds })
        .then(res => {
          setLoading(false)
          onClose()
          resetForm()
          return res
        })
        .catch(err => {
          setLoading(false)
          setSubmitting(false)
          if (typeof err.message !== 'string' && err.message.length > 0) {
            setError(err.message[0])
          } else {
            setError(err.message)
          }
        })
    }
  })

  const addConnections = (data: number[]) => {
    setConnectionIds(data)
  }

  return (
    <React.Fragment>
      <S.AddTaskModal
        title={
          <Typography variant='heading' weight='semibold'>
            {t('ADD_TASK')}
          </Typography>
        }
        open={open}
        size='large'
        closeHandler={onClose}
        disableBackdropClick
      >
        <form onSubmit={formik.handleSubmit}>
          <S.Input
            placeholder={t('TASK_NAME')}
            width='auto'
            style={{ maxWidth: '370px' }}
            error={Boolean(formik.touched.title && formik.errors.title)}
            {...formik.getFieldProps('title')}
          />
          {formik.touched.title && formik.errors.title ? <Error>{formik.errors.title}</Error> : null}
          <Container flex bottom={1.2} top={0.5}>
            <Typography size='medium' weight='medium' inline>
              {moment().format('DD.MM.YYYY HH:mm')}
            </Typography>
          </Container>
          <Grid>
            <Grid.Item small={12} medium={6}>
              <Container flex>
                <S.OptionName size='medium' inline>
                  {t('CREATOR')}
                </S.OptionName>
                <Typography size='medium' weight='medium' inline>
                  {user?.first_name} {user?.last_name}
                </Typography>
              </Container>
              <Container flex alignItems='center'>
                <S.OptionName size='medium' inline className='mb-0'>
                  {t('RECIPIENT')}
                </S.OptionName>
                <S.SearchInput
                  placeholder={t('ENTER_NAME')}
                  icon='search'
                  iconPosition='left'
                  style={{ marginLeft: '-14px' }}
                />
              </Container>
            </Grid.Item>
            <Grid.Item small={12} medium={6}>
              <Container flex>
                <S.OptionName size='medium' inline>
                  {t('STATUS')}
                </S.OptionName>
                <Typography size='medium' weight='medium' inline>
                  <ColorDot className='yellow' /> {t('WAITING_FOR_COMPLETION')}
                </Typography>
              </Container>
              <Container flex alignItems='center'>
                <S.OptionName size='medium' inline className='mt-5'>
                  {t('COMPLETED_BY')}
                </S.OptionName>
              </Container>
            </Grid.Item>
          </Grid>
          <Container fullWidth top={1}>
            <S.Heading weight='semibold'>{t('TASK')}</S.Heading>
            <S.Input
              placeholder={t('TASK_DESCRIPTION')}
              multiline
              rows={4}
              width='full'
              style={{ maxWidth: '580px', padding: '10px' }}
              error={Boolean(formik.touched.description && formik.errors.description)}
              {...formik.getFieldProps('description')}
            />
            {formik.touched.description && formik.errors.description ? (
              <Error>{formik.errors.description}</Error>
            ) : null}
          </Container>
          {error && <Error> {error} </Error>}
          <Container fullWidth top={1}>
            <S.Heading weight='semibold'>{t('CONNECTIONS')}</S.Heading>
            <ConnectionsContainer>
              {connectionIds.length > 0 &&
                connections
                  .filter((item: any) => connectionIds.indexOf(item.id) > -1)
                  .map((item: any) => <ConnectionItem key={item.id} icon={<EicIcon />} text={item.name} />)}
            </ConnectionsContainer>
            <Container flex justifyContent='space-between'>
              <div>
                <S.Toolbox
                  icon={<PlusIcon stroke='#132C46' height='35px' />}
                  size='medium'
                  onClick={handleOpenAddConnectionModal}
                />
              </div>
              <Container flex fullWidth alignItems='flex-end' justifyContent='flex-end'>
                <Button
                  size='xsmall'
                  color='blue'
                  fullWidth
                  style={{ maxWidth: '180px', fontSize: '13px', marginRight: '20px', opacity: 0.5 }}
                  onClick={onClose}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  type='submit'
                  size='xsmall'
                  color='blue'
                  fullWidth
                  style={{ maxWidth: '380px', fontSize: '13px' }}
                  disabled={loading}
                >
                  {t('SAVE')}
                </Button>
              </Container>
            </Container>
          </Container>
        </form>
      </S.AddTaskModal>

      {openAddConnectionModal ? (
        <AddConnectionModal
          onClose={() => setOpenAddConnectionModal(false)}
          onAddConnections={data => addConnections(data)}
        />
      ) : null}
    </React.Fragment>
  )
}

AddTaskModal.displayName = 'AddTaskModal'
export default AddTaskModal
