import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { PAYMENT_INVOICE_STATUS } from 'src/shared/interfaces'

interface PaymentInvoiceStatusProps {
  value?: number | string
}

const PaymentInvoiceStatus = (props: PaymentInvoiceStatusProps) => {
  const { t } = useTranslation()

  const statuses = useMemo(() => {
    return Object.keys(PAYMENT_INVOICE_STATUS)
      .map((key, index, array) => {
        if (index >= array.length / 2) return null
        const item = PAYMENT_INVOICE_STATUS[key as never]
        return {
          id: key,
          name: t(`PAYMENT_INVOICE_STATUS.${item}` as never)
        }
      })
      .filter(Boolean)
  }, [])

  if (!props.value) return null

  const status = statuses.find(paymentInvoiceStatus => Number(paymentInvoiceStatus?.id) === Number(props.value))
  let color

  switch (Number(status?.id)) {
    case 1:
      color = '#f1e0a7'
      break
    case 2:
      color = '#ffa300'
      break
    case 3:
      color = '#8abd25'
      break
    case 4:
      color = '#ff3b30'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{status?.name}</span>
    </Container>
  )
}

PaymentInvoiceStatus.displayName = 'PaymentInvoiceStatus'
export default PaymentInvoiceStatus
