import React, { MouseEvent, ReactNode, useState } from 'react'
import { ColorsType, Container, ExtendedColorsType, Typography } from '@otion-core/sandy'

import { TrashIcon } from '../../assets/icons'
import { IconButton } from '../commonStyled'
import PromptModal from '../PromptModal'
import * as S from './styles'

interface ConnectionItemProps {
  color?: ColorsType
  icon: ReactNode
  text?: string
  status?: ReactNode
  onClick?: () => void
  onDelete?: () => void
  deletePrompt?: string
  to?: string
  className?: string
  backgroundColor?: ExtendedColorsType
}

const ConnectionItem = (props: ConnectionItemProps) => {
  const { icon, text, status, onClick, to, onDelete } = props
  const [openPromptModal, setOpenPromptModal] = useState(false)

  const handleClickOnRemoveButton = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setOpenPromptModal(true)
  }

  return (
    <React.Fragment>
      <S.Container
        backgroundColor={props.backgroundColor}
        icon={icon}
        to={to}
        onClick={onClick}
        text={
          <Container flex direction='column'>
            <Typography
              size='inherit'
              align='center'
              font='secondary'
              className='item-text'
              title={text?.length && text.length > (status ? 32 : 50) ? text : undefined}
            >
              {text?.substring(0, status ? 32 : 50)}
            </Typography>

            <Container flex justifyContent='center' right='xsmall'>
              <Typography
                size='inherit'
                font='secondary'
                align='center'
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 140 }}
              >
                {status || null}
              </Typography>
            </Container>

            {onDelete ? (
              <Container>
                <IconButton
                  className='button-remove'
                  variant='primary'
                  color='trasparent'
                  fullWidth={false}
                  stopPropagation
                  onClick={handleClickOnRemoveButton}
                >
                  <TrashIcon height={20} />
                </IconButton>
              </Container>
            ) : null}
          </Container>
        }
        size='medium'
        className={props.className}
      />

      {onDelete && openPromptModal ? (
        <PromptModal
          title={props.deletePrompt || 'Vymazať položku?'}
          color={props.color || 'green'}
          closeHandler={() => setOpenPromptModal(false)}
          onConfirm={onDelete}
        />
      ) : null}
    </React.Fragment>
  )
}

ConnectionItem.displayName = 'ConnectionItem'
export default ConnectionItem
