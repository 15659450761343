import { BILL_TYPE } from './expenses.interface'

export interface IBankAccount {
  account_balance: string
  amount: string
  available_balance: string
  balance_expenses: string
  created_by: string
  created_on: string
  current_balance: string
  description: string
  economy_id: number
  id: number
  incorrect_payment: boolean
  message: string
  pairing_id: number
  payment_delivery_date: string
  payment_number_of_pairings: number
  status: number
  tags: string
  title: string
  turnover_date: string
  type: string
  variable_symbol: string
}

export interface IBankAccountFilter {
  id?: number
  search?: string
  status?: number
  expected_turnover_date_start?: string | Date
  expected_turnover_date_end?: string | Date
  expected_amount_from?: string
  expected_amount_to?: string
}

export interface IBankAccountStatus {
  id: number
  name: string
}

// eslint-disable-next-line no-shadow
export enum BankAccountStatus {
  NOT_PAIRED = 1,
  PAIRED,
  INCORRECT_PAYMENT
}

// eslint-disable-next-line no-shadow
export enum BillStatusEnum {
  UNVERIFIED = 'UNVERIFIED',
  NEW = 'NEW',
  CHECKED = 'CHECKED'
}

export interface IBill {
  amount_without_vat: string
  another_alignment: string
  balance: string
  cent_settlement: string
  cost_center: string
  created_at: string
  created_by: number
  delivery_date: string
  description: string
  due_date: string
  files?: IFile[]
  id: number
  invoice_id: number
  invoice_number: string
  issue_date: string
  payment_date: string
  profit_center: string
  source: string
  status: BillStatusEnum
  subscriber: string
  total_amount: string
  type: BILL_TYPE
  variable_symbol: string
  vat: string
  partner?: string
  partner_id?: number
}

export interface IUpdateDtoBill extends Omit<IBill, 'balance' | 'total_amount' | 'amount_without_vat' | 'vat'> {
  balance: number
  total_amount: number
  amount_without_vat: number
  vat: number
}

export interface IUpdateDtoBankAccount extends Pick<IBankAccount, 'amount'> {}

export interface IFile {
  created_at: string
  id: number
  key: string
  url: string
}

export interface IBillsFilter {
  id?: number
  search?: string
  balance?: number | string
  status?: string
  expected_delivery_date_start?: string | Date
  expected_delivery_date_end?: string | Date
  expected_total_amount_from?: string
  expected_total_amount_to?: string
}

export interface IFinancialBillHistory {
  id: number
  from_bank_account_id?: number
  from_bill_id?: number
  from_bill_type?: BILL_TYPE
  from_bill_description?: string
  to_bill_id: number
  to_bill_type?: BILL_TYPE
  to_bill_description?: string
  amount: number
  is_accounted: boolean
  created_at: string
  created_by: number
}

export interface IPairManuallyPayload {
  bankAccountId: number
  billId: number
}

export interface IMoveBalancePayload {
  fromBillId: number
  toBillId: number
}
