import {
  TableSort,
  PromiseAction,
  ICustomer,
  ICustomersFilter,
  ICustomerDetails,
  ICreateCustomerPayload
} from '../../shared/interfaces'
import * as CustomersApi from '../../apis/customers.api'

export const GET_CUSTOMERS_TRIGGERED = 'GET_CUSTOMERS_TRIGGERED'
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE'

export const getCustomers = (roleIds?: number[]) => (
  filter: ICustomersFilter,
  sort: TableSort<ICustomer> | undefined,
  page: number,
  size = 10
): PromiseAction => {
  const filterWithRoles = { ...filter, customer_role_ids: roleIds }
  const promise = CustomersApi.getAllCustomers(filterWithRoles, sort, page, size)
  return {
    onRequest: GET_CUSTOMERS_TRIGGERED,
    onSuccess: GET_CUSTOMERS_SUCCESS,
    onFailure: GET_CUSTOMERS_FAILURE,
    promise,
    payload: { filter, page, size }
  }
}

export const GET_CUSTOMER_DETAIL_TRIGGERED = 'GET_CUSTOMER_DETAIL_TRIGGERED'
export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS'
export const GET_CUSTOMER_DETAIL_FAILURE = 'GET_CUSTOMER_DETAIL_FAILURE'

export function getCustomerDetail(customerId: number): PromiseAction {
  const promise = CustomersApi.getCustomerDetail(customerId)
  return {
    onRequest: GET_CUSTOMER_DETAIL_TRIGGERED,
    onSuccess: GET_CUSTOMER_DETAIL_SUCCESS,
    onFailure: GET_CUSTOMER_DETAIL_FAILURE,
    promise,
    payload: { customerId }
  }
}

export const UPDATE_CUSTOMER_DETAIL_TRIGGERED = 'UPDATE_CUSTOMER_DETAIL_TRIGGERED'
export const UPDATE_CUSTOMER_DETAIL_SUCCESS = 'UPDATE_CUSTOMER_DETAIL_SUCCESS'
export const UPDATE_CUSTOMER_DETAIL_FAILURE = 'UPDATE_CUSTOMER_DETAIL_FAILURE'

export function updateCustomerDetail(eidId: number, values: ICustomerDetails): PromiseAction {
  const promise = CustomersApi.updateCustomerDetail(eidId, values)
  return {
    onRequest: UPDATE_CUSTOMER_DETAIL_TRIGGERED,
    onSuccess: UPDATE_CUSTOMER_DETAIL_SUCCESS,
    onFailure: UPDATE_CUSTOMER_DETAIL_FAILURE,
    promise,
    payload: { eidId, values }
  }
}

export const CREATE_CUSTOMER_TRIGGERED = 'CREATE_CUSTOMER_TRIGGERED'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE'

export function createCustomerDetail(values: ICreateCustomerPayload): PromiseAction {
  const promise = CustomersApi.createCustomer(values)
  return {
    onRequest: CREATE_CUSTOMER_TRIGGERED,
    onSuccess: CREATE_CUSTOMER_SUCCESS,
    onFailure: CREATE_CUSTOMER_FAILURE,
    promise,
    payload: { values }
  }
}

export const GET_CUSTOMERS_ROLES_TRIGGERED = 'GET_CUSTOMERS_ROLES_TRIGGERED'
export const GET_CUSTOMERS_ROLES_SUCCESS = 'GET_CUSTOMERS_ROLES_SUCCESS'
export const GET_CUSTOMERS_ROLES_FAILURE = 'GET_CUSTOMERS_ROLES_FAILURE'

export const getCustomersRoles = () => {
  const promise = CustomersApi.getCustomerRoles()
  return {
    onRequest: GET_CUSTOMERS_ROLES_TRIGGERED,
    onSuccess: GET_CUSTOMERS_ROLES_SUCCESS,
    onFailure: GET_CUSTOMERS_ROLES_FAILURE,
    promise
  }
}

export const GET_ELECTRICITY_CUSTOMERS_TRIGGERED = 'GET_ELECTRICITY_CUSTOMERS_TRIGGERED'
export const GET_ELECTRICITY_CUSTOMERS_SUCCESS = 'GET_ELECTRICITY_CUSTOMERS_SUCCESS'
export const GET_ELECTRICITY_CUSTOMERS_FAILURE = 'GET_ELECTRICITY_CUSTOMERS_FAILURE'

export const getElectricityCustomers = (): PromiseAction => {
  const promise = CustomersApi.getElectricityCustomers()
  return {
    onRequest: GET_ELECTRICITY_CUSTOMERS_TRIGGERED,
    onSuccess: GET_ELECTRICITY_CUSTOMERS_SUCCESS,
    onFailure: GET_ELECTRICITY_CUSTOMERS_FAILURE,
    promise
  }
}

export const GET_ALL_PARTNERS_TRIGGERED = 'GET_ALL_PARTNERS_TRIGGERED'
export const GET_ALL_PARTNERS_SUCCESS = 'GET_ALL_PARTNERS_SUCCESS'
export const GET_ALL_PARTNERS_FAILURE = 'GET_ALL_PARTNERS_FAILURE'

export const getAllPartners = (): PromiseAction => {
  const promise = CustomersApi.getAllPartners()
  return {
    onRequest: GET_ALL_PARTNERS_TRIGGERED,
    onSuccess: GET_ALL_PARTNERS_SUCCESS,
    onFailure: GET_ALL_PARTNERS_FAILURE,
    promise
  }
}

export const CREATE_USER_FOR_CUSTOMER_TRIGGERED = 'CREATE_USER_FOR_CUSTOMER_TRIGGERED'
export const CREATE_USER_FOR_CUSTOMER_SUCCESS = 'CREATE_USER_FOR_CUSTOMER_SUCCESS'
export const CREATE_USER_FOR_CUSTOMER_FAILURE = 'CREATE_USER_FOR_CUSTOMER_FAILURE'

export const createUserForCustomer = (customerId: number): PromiseAction => {
  const promise = CustomersApi.createUserForCustomer(customerId)
  return {
    onRequest: CREATE_USER_FOR_CUSTOMER_TRIGGERED,
    onSuccess: CREATE_USER_FOR_CUSTOMER_SUCCESS,
    onFailure: CREATE_USER_FOR_CUSTOMER_FAILURE,
    promise
  }
}

export const GET_CUSTOMERS_WITHOUT_ACCOUNT_TRIGGERED = 'GET_CUSTOMERS_WITHOUT_ACCOUNT_TRIGGERED'
export const GET_CUSTOMERS_WITHOUT_ACCOUNT_SUCCESS = 'GET_CUSTOMERS_WITHOUT_ACCOUNT_SUCCESS'
export const GET_CUSTOMERS_WITHOUT_ACCOUNT_FAILURE = 'GET_CUSTOMERS_WITHOUT_ACCOUNT_FAILURE'

export const getCustomersWithoutAccount = (): PromiseAction => {
  const promise = CustomersApi.getCustomersWithoutAccount()
  return {
    onRequest: GET_CUSTOMERS_WITHOUT_ACCOUNT_TRIGGERED,
    onSuccess: GET_CUSTOMERS_WITHOUT_ACCOUNT_SUCCESS,
    onFailure: GET_CUSTOMERS_WITHOUT_ACCOUNT_FAILURE,
    promise
  }
}

export const RELOAD_COMPANY_INFO_TRIGGERED = 'RELOAD_COMPANY_INFO_TRIGGERED'
export const RELOAD_COMPANY_INFO_SUCCESS = 'RELOAD_COMPANY_INFO_SUCCESS'
export const RELOAD_COMPANY_INFO_FAILURE = 'RELOAD_COMPANY_INFO_FAILURE'

export function postReloadCompanyInfo(partnerId: number): PromiseAction {
  const promise = CustomersApi.reloadCompanyInfo(partnerId)
  return {
    onRequest: RELOAD_COMPANY_INFO_TRIGGERED,
    onSuccess: RELOAD_COMPANY_INFO_SUCCESS,
    onFailure: RELOAD_COMPANY_INFO_FAILURE,
    promise,
    payload: { partnerId }
  }
}
