import React from 'react'
import { Typography, BasePage } from '@otion-core/sandy'

const NotFoundPage = () => (
  <BasePage title='Page not found' headerTitle='Encare' isLanding centered>
    <Typography variant='heading' size='xlarge' weight='semibold'>
      404
    </Typography>
    <Typography size='xlarge'>Nepodarilo sa nám nájsť stránku, ktorú hľadáte.</Typography>
  </BasePage>
)

NotFoundPage.displayName = 'NotFoundPage'
export default NotFoundPage
