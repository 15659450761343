import React from 'react'
import { createRoot } from 'react-dom/client'

import './i18n'
import App from './App'
import Provider from './redux/store'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <Provider>
    <App />
  </Provider>
)
