import React, { SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Badge, ColorsType, Container, Icon, Input, Typography } from '@otion-core/sandy'

import { IInvoicesFilter } from 'src/shared/interfaces'
import { useQuickFilters } from 'src/shared/hooks'
import { ButtonWhite } from 'src/components'
import * as S from 'src/components/commonStyled'

import FilterPaneModal, { TabConfig } from './FilterPaneModal'
import { ExportTypeEnum } from '../../shared/constants'

interface QuickFilter<T> {
  filterName: string
  filterValues: T
  label: string
  count?: number
}

interface FilterMenuProps<FilterValues = unknown> {
  color: ColorsType
  applyFilter: (payload: SetStateAction<FilterValues> & FilterValues) => void
  filter: FilterValues
  filteredCount?: number
  totalCount?: number
  searchPlaceholder?: string
  quickFilters?: QuickFilter<FilterValues>[]
  modalFilterTabs?: TabConfig<FilterValues>[]
  exportType?: ExportTypeEnum
}

function FilterPane<T>(props: FilterMenuProps<T>) {
  const { t: tCommon } = useTranslation()
  const { filter, applyFilter } = props
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const { selectedFilterOption, onFilterOptionChange } = useQuickFilters({ applyFilter })

  return (
    <Formik
      initialValues={{ search: '' }}
      onSubmit={values => {
        onFilterOptionChange('search', { search: values?.search?.trim() } as never)
      }}
    >
      {({ resetForm }: FormikProps<IInvoicesFilter>) => (
        <Form>
          <S.FilterContainer>
            <Container flex style={{ maxWidth: '230px' }} bottom={2} alignItems='center'>
              {props.modalFilterTabs?.length ? (
                <Container right='small'>
                  {Object.values(filter as object)?.some(Boolean) ? (
                    <S.ColorDot
                      color='red'
                      size={10}
                      style={{ position: 'absolute', zIndex: 1, marginTop: -2, marginLeft: -2 }}
                    />
                  ) : null}
                  <Icon name='filter' color={props.color} onClick={() => setOpenFilterModal(true)} stopPropagation />
                </Container>
              ) : null}

              <Field
                name='search'
                as={Input}
                icon='search'
                iconPosition='left'
                placeholder={props.searchPlaceholder}
                style={{ height: 40, overflow: 'hidden' }}
              />
            </Container>

            <Typography size='medium' weight='medium'>
              {props.filteredCount}/{props.totalCount}
            </Typography>

            <S.FilterOptions top='medium'>
              <ButtonWhite
                color={props.color}
                onClick={() => onFilterOptionChange('all', undefined, resetForm)}
                active={Object.keys(filter as object).length === 0}
              >
                {tCommon('ALL_ITEMS')}
              </ButtonWhite>

              {props.quickFilters?.map(quickFilter => (
                <ButtonWhite
                  key={quickFilter.filterName}
                  color={props.color}
                  onClick={() => onFilterOptionChange(quickFilter.filterName, quickFilter.filterValues, resetForm)}
                  active={selectedFilterOption === quickFilter.filterName}
                  rightAdornment={
                    quickFilter.count ? (
                      <Badge spacing='xsmall' color={props.color} active>
                        {quickFilter.count}
                      </Badge>
                    ) : (
                      undefined
                    )
                  }
                >
                  {quickFilter.label}
                </ButtonWhite>
              ))}
            </S.FilterOptions>

            {openFilterModal ? (
              <FilterPaneModal<T>
                color={props.color}
                onClose={() => setOpenFilterModal(false)}
                onApply={filterValues => onFilterOptionChange('custom', filterValues)}
                filter={filter}
                modalFilterTabs={props.modalFilterTabs}
                exportType={props.exportType}
              />
            ) : null}
          </S.FilterContainer>
        </Form>
      )}
    </Formik>
  )
}

FilterPane.displayName = 'FilterPane'
export default FilterPane
