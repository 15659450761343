import { format, isValid, parse } from 'date-fns'
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker'
import React, { ChangeEventHandler, useRef, useState, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import { FieldProps } from 'formik'
import { Icon } from '@otion-core/sandy'
import { sk } from 'date-fns/locale'
import moment from 'moment'

import 'react-day-picker/dist/style.css'
import styles from './PopupDatePicker.module.scss'

function formatToCustomDate(date: Date): string {
  const formattedDate = format(date, 'yyyy-MM-dd')
  return `${formattedDate}T00:00:00Z`
}

interface PopupDatePickerProps extends Partial<FieldProps> {
  inputProps?: any
  error?: boolean
  value?: string | Date
  minDate?: Date
  maxDate?: Date
  name?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const PopupDatePicker: React.FC<PopupDatePickerProps> = ({ field, inputProps, error, ...props }) => {
  const value = field?.value || props.value
  const onChange = field?.onChange || props.onChange
  const name = field?.name || props.name || ''
  const [inputValue, setInputValue] = useState<string>('')
  const [selected, setSelected] = useState<Date>()
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const popperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const closePopper = () => {
    setIsPopperOpen(false)
    buttonRef?.current?.focus()
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsPopperOpen(!isPopperOpen)
  }

  const handleDaySelect: SelectSingleEventHandler = useCallback(
    date => {
      setSelected(date)
      if (date) {
        setInputValue(format(date, 'dd.MM.y'))
        const offset = date.getTimezoneOffset() * 60000
        const utcDate = new Date(date.getTime() - offset)
        const utcDateStr = utcDate.toISOString().split('.')[0] + 'Z'
        onChange?.({ target: { name, value: utcDateStr } } as any)
        closePopper()
      } else {
        setInputValue('')
      }
    },
    [field]
  )

  if (name === 'delivery_start') console.log(value)

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    const date = parse(e.target.value, 'dd.MM.y', new Date())
    if (isValid(date) && moment(date).isAfter('1900-01-01')) {
      setInputValue(e.target.value)
      setSelected(date)
      onChange?.({ target: { name, value: formatToCustomDate(date) } } as any)
    } else {
      setInputValue(e.target.value)
    }
  }, [])

  const handleInputBlur: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    const date = parse(e.target.value, 'dd.MM.y', new Date())
    if (isValid(date) && moment(date).isAfter('1900-01-01')) {
      setSelected(date)
      onChange?.({ target: { name, value: formatToCustomDate(date) } } as any)
    } else {
      setInputValue(moment(selected).format('DD.MM.YYYY') || '')
      setSelected(undefined)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
        closePopper()
      }
    }
    if (isPopperOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPopperOpen, popperRef])

  useEffect(() => {
    if (value instanceof Date && !isNaN(value.valueOf())) {
      setSelected(value)
      setInputValue(format(value, 'dd.MM.y'))
    } else if (typeof value === 'string') {
      const parsedDate = new Date(value)
      if (!isNaN(parsedDate.valueOf())) {
        setSelected(parsedDate)
        setInputValue(format(parsedDate, 'dd.MM.y'))
      }
    }
  }, [value])

  return (
    <div className={classNames(styles.block)} ref={popperRef}>
      <input
        className={`${styles.input} ${error ? styles.inputError : ''}`}
        type='text'
        placeholder=''
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        {...inputProps}
      />

      <input hidden type='text' {...field} />

      <button
        className={classNames(styles.button)}
        ref={buttonRef}
        onClick={e => {
          handleButtonClick(e)
        }}
      >
        <span className={classNames(styles.button_icon)}>
          <Icon name='calendar' />
        </span>
      </button>

      {isPopperOpen && (
        <div className={styles.popup}>
          <DayPicker
            fromDate={props.minDate}
            toDate={props.maxDate}
            showOutsideDays
            disabled={{ after: props.maxDate, before: props.minDate } as never}
            initialFocus={isPopperOpen}
            mode='single'
            defaultMonth={selected}
            selected={selected}
            onSelect={handleDaySelect}
            locale={sk}
            modifiersClassNames={{
              selected: styles.picker__selected,
              today: styles.picker__today
            }}
            fromYear={2020}
            toYear={new Date().getFullYear() + 5}
            captionLayout='dropdown-buttons'
          />
        </div>
      )}
    </div>
  )
}

PopupDatePicker.displayName = 'PopupDatePicker'
export default PopupDatePicker
