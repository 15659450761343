import styled, { css } from 'styled-components'
import { Button as SButton, Input as SInput } from '@otion-core/sandy/build/components'
import { Paper, Modal as SModal } from '@otion-core/sandy'

const Modal = styled(SModal)`
  .white-color {
    overflow: inherit;
  }
`

const Container = styled.div`
  width: 230px;
  margin-right: 30px;
`

const FilterButton = styled(SButton)`
  min-width: 38px !important;
  height: 38px !important;
  border-radius: 50% !important;
  padding: 0 !important;
  border: none !important;
`

const Input = styled(SInput)`
  input {
    line-height: 38px !important;
  }
`

const Button = styled(SButton)<{ selected?: boolean }>`
  background-color: white !important;
  color: #242424 !important;
  ${props =>
    props.selected &&
    css`
      background-color: #132c46 !important;
      color: white !important;
    `}
  min-width: 200px !important;
  margin-right: 20px !important;
  border: none !important;
  font-size: 13px !important;
  box-shadow: 0 4px 20px rgb(5 2 20 / 5%) !important;
  padding: 0 20px !important;
`

const FilterMenuButton = styled(SButton)<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? '#132c46' : 'white')} !important;
  border: ${({ selected }) => (!selected ? 'solid 1px #c5c7c8' : 'none')} !important;
  box-shadow: 0 4px 20px rgb(5 2 20 / 5%) !important;
  color: ${({ selected }) => (selected ? 'white' : '#242424')} !important;
  font-size: 13px !important;
  justify-content: flex-start !important;
  margin-bottom: 15px !important;
  min-width: 200px !important;
  padding: 0 20px !important;
`

const DatePicker = styled(Input)`
  position: relative;
  width: 100%;
  height: 48px;

  > span {
    position: absolute;
    right: 20px;
    cursor: pointer;
    background: white;
    pointer-events: none;
  }
`

const LeftPanel = styled.div`
  align-items: flex-start;
  border-right: 1px solid #c5c7c8;
  display: flex;
  flex-direction: column;
  width: 225px;
`

const RightPanel = styled.div`
  flex: 1;
  padding: 0 24px;
`

const ContactsInfoList = styled(Paper)`
  padding: 20px 20px 10px;
  margin: 0;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .footer {
    cursor: pointer;
    border-top: 1px solid white;
    padding: 10px 0;
  }
`

const ContactsListItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid white;
  padding: 10px 0;

  p {
    color: white !important;
  }
`

const RZMMenu = styled(Paper)<{ open?: boolean }>`
  height: ${props => (!props.open ? '75px' : 'unset')};
  overflow: hidden;
  transition: all ease 0.3s;
`

const RZMMenuTitle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  min-height: 75px;
  padding: 20px;

  svg {
    margin-right: 10px;
    transform: rotate(0deg);
    transition: all ease 0.3s;

    &.open {
      transform: rotate(180deg);
    }
  }
`

const RZMMenuList = styled.div`
  padding: 0 20px 10px;

  .footer {
    border-top: 2px solid #c5c7c8;
    padding: 10px 0;
    cursor: pointer;
  }
`

const RZMMenuListItem = styled.div`
  padding: 10px 20px;
  border-bottom: 2px solid #c5c7c8;
  cursor: pointer;

  p {
    line-height: 1rem;
  }

  &:last-child {
    border: none;
  }
`

const FilterOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  margin-top: 15px;
`

export {
  Modal,
  Container,
  FilterButton,
  Input,
  Button,
  FilterMenuButton,
  DatePicker,
  LeftPanel,
  RightPanel,
  ContactsInfoList,
  ContactsListItem,
  RZMMenu,
  RZMMenuList,
  RZMMenuListItem,
  RZMMenuTitle,
  FilterOptions
}
