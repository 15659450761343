import Http from './api'
import { IWarning, IWarningFilter, IPaginationResponse, TableSort } from '../shared/interfaces'
import { generatePaginationPayload } from '../lib/paginationPayload'

export const getWarnings = (filter: IWarningFilter, sort: TableSort<IWarning> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter: {
      warning_type_id: filter.warning_type_id
    },
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IWarning>>('/warnings', payload)
}

export const getWarningTypes = () => {
  return Http.get<string[]>('/warnings/types')
}
