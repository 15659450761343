// eslint-disable-next-line no-shadow
export enum BILL_TYPE {
  EXPENSE = 'EXPENSE',
  INCOME = 'INCOME'
}

// eslint-disable-next-line no-shadow
export enum INCOME_TYPE {
  INVOICE = 'INVOICE',
  PROFORMA = 'PROFORMA',
  ACCEPTED_LOAN = 'ACCEPTED_LOAN',
  COMMITMENT_PLUS = 'COMMITMENT_PLUS',
  RECEIVABLE_MINUS = 'RECEIVABLE_MINUS',
  CAPITAL = 'CAPITAL',
  OTHER_INCOME = 'OTHER_INCOME'
}

// eslint-disable-next-line no-shadow
export enum PROFIT_CENTER {
  ELECTRICITY_DELIVERY = 'Dodávka elektriny',
  GAS_DELIVERY = 'Dodávka plynu',
  LEASING = 'Leasing',
  COMMODITY_MEDIATION = 'Sprostredkovanie komodity',
  OTHER = 'Ostatná činnosť'
}

// eslint-disable-next-line no-shadow
export enum COST_CENTER {
  ENIO = 'ENIO',
  SOFTWARE_DEVELOPMENT = 'Vývoj software-u',
  MANAGEMENT = 'Management',
  TECH_PURCHASE = 'Nákup technológie',
  ELECTRICITY = 'Elektrina',
  TRAINING_CONFERENCES = 'Školenia, konferencie',
  BANK_FEES_INSURANCE = 'Bankové poplatky, poistenie',
  SALES_MANAGEMENT = 'Manažment obchodníkov',
  NATURAL_GAS = 'Zemný plyn',
  OTHER_COSTS = 'Ostatné náklady',
  REPRESENTATION = 'Repre',
  MARKETING = 'Marketingové náklady',
  SALES_CHANNEL_COSTS = 'Náklady obchodného kanálu',
  OFFICE = 'Kancelária',
  ACCOUNTING = 'Účtovníctvo',
  OPERATING_COSTS = 'Náklady na výkon činnosti',
  LEGAL_SERVICES = 'Právne služby',
  OTHER_SALES_CHANNEL_COSTS = 'Ostatné náklady obchodného kanálu',
  BACKOFFICE = 'Backoffice',
  RECRUITMENT_COSTS = 'Náklady na nábor obchodníkov',
  CRM_SW_LICENCES = 'Správa CRM a SW licencie'
}

// eslint-disable-next-line no-shadow
export enum EXPENSE_TYPE {
  COST = 'COST',
  VAT = 'VAT',
  PROFORMA = 'PROFORMA',
  EXCISE_DUTY = 'EXCISE_DUTY',
  OTHER_TAX = 'OTHER_TAX',
  PO_INCOME_TAX = 'PO_INCOME_TAX',
  DIVIDENDS = 'DIVIDENDS',
  COMMITMENT_MINUS = 'COMMITMENT_MINUS',
  RECEIVABLE_PLUS = 'RECEIVABLE_PLUS',
  FINE = 'FINE',
  INTEREST = 'INTEREST',
  WAGES_AND_CONTRIBUTIONS = 'WAGES_AND_CONTRIBUTIONS',
  OTHER_EXPENSE = 'OTHER_EXPENSE'
}

export interface IIncomeCreatePayload {
  description: string
  source: EXPENSE_TYPE
  partner_id?: number
  invoice_id?: number
  variable_symbol?: string
  invoice_number?: string
  delivery_date: Date | string
  issue_date: Date | string
  due_date: Date | string
  amount_without_vat: number
  vat: number
  total_amount: string
  cent_settlement?: string
  profit_center?: string
  files?: File[]
  type: BILL_TYPE
}

export interface IExpenseCreatePayload {
  description: string
  source: EXPENSE_TYPE
  invoice_id?: number
  variable_symbol?: string
  invoice_number?: string
  delivery_date: Date | string
  issue_date: Date | string
  due_date: Date | string
  amount_without_vat: number
  vat: number
  total_amount: string
  cent_settlement?: string
  cost_center?: string
  files?: File[]
  type: BILL_TYPE
  partner_id?: number
}

export interface IExpenseUploadFile {
  files: File[]
}
