import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ColorsType, Container, Modal, Typography } from '@otion-core/sandy'

interface PromptModalProps {
  title: string
  color: ColorsType
  description?: React.ReactNode
  closeHandler: () => void
  onConfirm: () => void | Promise<void>
  dangerConfirm?: boolean
}

const PromptModal = ({ title, description, color, closeHandler, onConfirm, dangerConfirm }: PromptModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t: tCommon } = useTranslation()

  const handleClickOnConfirmButton = async () => {
    setIsLoading(true)
    await onConfirm()
    setIsLoading(false)
    closeHandler()
  }

  return (
    <Modal open closeHandler={closeHandler} title={title}>
      <Container style={{ minWidth: 400 }}>
        <Container>
          {description ? (
            <Container top='small' bottom='xlarge'>
              {description}
            </Container>
          ) : null}

          <Container fullWidth top='small'>
            <Container flex justifyContent='space-between' style={{ gap: 20 }}>
              <Button
                size='xsmall'
                color='white'
                fullWidth
                style={{ fontSize: '13px' }}
                onClick={closeHandler}
                stopPropagation
              >
                <Typography size='medium' weight='semibold'>
                  {tCommon('CANCEL')}
                </Typography>
              </Button>

              <Button
                size='xsmall'
                variant='primary'
                color={dangerConfirm ? 'red' : `${color}.light`}
                fullWidth
                style={{ fontSize: '13px' }}
                onClick={handleClickOnConfirmButton}
                stopPropagation
                loading={isLoading}
                disabled={isLoading}
              >
                <Typography size='medium' weight='semibold' color={dangerConfirm ? 'white' : undefined}>
                  {tCommon('YES')}
                </Typography>
              </Button>
            </Container>
          </Container>
        </Container>
      </Container>
    </Modal>
  )
}

PromptModal.displayName = 'PromptModal'

export default PromptModal
