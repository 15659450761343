import React, { createRef, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { TableCell, TableHead as MuiTableHead, TableRow } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Checkbox } from '@otion-core/sandy'

import { IColumn } from '../../shared/interfaces'
import { TableSortIcon } from '../../assets/icons'
import { SortDirection } from './Table'
import * as S from './styles'

const DEFAULT_MAX_WIDTH_CELL = 600

interface TableHeadProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: SortDirection
  orderBy: string
  rowCount: number
  columns: IColumn[]
}

const TableHead = (props: TableHeadProps) => {
  const location = useLocation()
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns } = props

  const classes = useStyles()

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const columnRefs = columns.map(() => createRef<HTMLDivElement>())
  const resizingIndex = useRef(-1)

  // need to implement load when open again
  useEffect(() => {
    loadColumnInfoLocalStorage()
    document.onmousemove = handleOnMouseMove
    document.onmouseup = handleOnMouseUp
    return () => {
      document.onmousemove = null
      document.onmouseup = null
    }
  }, [columnRefs])

  const loadColumnInfoLocalStorage = () => {
    let columnsInfo: any = localStorage.getItem(`encare-app-columnsInfo${location.pathname?.split('/').join('-')}`)
    if (columnsInfo) {
      columnsInfo = JSON.parse(columnsInfo)
      Object.keys(columnsInfo).forEach((colField, index) => {
        const ref = columnRefs[index]?.current as HTMLElement
        if (ref?.parentElement) ref.parentElement.style.minWidth = columnsInfo[colField]
      })
    }
  }

  const saveColumnInfoLocalStorage = () => {
    const columnsInfo: Record<any, string | undefined> = {}
    columns.forEach((col, index) => {
      const ref = columnRefs[index].current as HTMLElement
      columnsInfo[col.id] = ref?.parentElement?.style.minWidth
    })
    localStorage.setItem(
      `encare-app-columnsInfo${location.pathname?.split('/').join('-')}`,
      JSON.stringify(columnsInfo)
    )
  }

  const adjustWidthColumn = (index: number, width: number) => {
    const maxWidth = DEFAULT_MAX_WIDTH_CELL
    const newWidth = width > maxWidth ? maxWidth : width

    const ref = columnRefs[index].current as HTMLElement
    if (ref?.parentElement) ref.parentElement.style.minWidth = newWidth + 'px'
  }

  const setCursorDocument = (isResizing: boolean) => {
    document.body.style.cursor = isResizing ? 'col-resize' : 'auto'
  }

  const handleOnMouseMove = (e: MouseEvent) => {
    if (resizingIndex.current >= 0) {
      const ref = columnRefs[resizingIndex.current].current
      const newWidth = e.clientX - (ref?.parentElement?.getBoundingClientRect().left || 0)
      adjustWidthColumn(resizingIndex.current, newWidth)
    }
  }

  const handleOnMouseUp = () => {
    resizingIndex.current = -1
    saveColumnInfoLocalStorage()
    setCursorDocument(false)
  }

  const onClickResizeColumn = (index: number) => {
    resizingIndex.current = index
    setCursorDocument(true)
  }

  const onDoubleClickResize = (index: number) => {
    adjustWidthColumn(index, Object.values(columns)?.[index]?.width || 100)
    saveColumnInfoLocalStorage()
  }

  return (
    <MuiTableHead>
      <TableRow>
        <S.TableHeadCell padding='checkbox' style={{ marginLeft: '-10px', minWidth: 44 }}>
          <Checkbox value={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} />
        </S.TableHeadCell>
        {columns
          .filter(column => !column.hidden)
          .map((headCell, colIndex) => (
            <S.TableHeadCell
              key={headCell.id}
              align='left'
              padding='normal'
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ minWidth: `${headCell.width ? headCell.width + 'px' : 'unset'}` }}
            >
              <S.TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                <div className={`sort-icon ${orderBy === headCell.id ? order : ''}`}>
                  <TableSortIcon />
                </div>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </S.TableSortLabel>
              {headCell.resizable !== false ? (
                <S.ResizeLine
                  onDoubleClick={() => onDoubleClickResize(colIndex)}
                  onMouseDown={() => onClickResizeColumn(colIndex)}
                  ref={columnRefs[colIndex]}
                />
              ) : null}
            </S.TableHeadCell>
          ))}
        <TableCell style={{ width: 'auto' }} />
      </TableRow>
    </MuiTableHead>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    }
  })
)

TableHead.displayName = 'TableHead'

export default TableHead
