import { AnyAction } from 'redux'

import { ICustomer, ICustomersState, ICustomerDetails, IPaginationResponse, ICustomerRole } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: ICustomersState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  roles: [],
  details: undefined
}

const customersReducer = (state = initialState, action: AnyAction): ICustomersState => {
  switch (action.type) {
    case actionTypes.GET_CUSTOMERS_SUCCESS: {
      const response: IPaginationResponse<ICustomer> = action.response?.data
      if (!response) return state
      const totalCount =
        Object.keys(action.payload.filter).length && state.list.totalCount
          ? state.list.totalCount
          : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_CUSTOMER_DETAIL_SUCCESS:
    case actionTypes.RELOAD_COMPANY_INFO_SUCCESS:
    case actionTypes.UPDATE_CUSTOMER_DETAIL_SUCCESS: {
      const details: ICustomerDetails = action.response?.data
      return { ...state, details }
    }

    case actionTypes.CREATE_CUSTOMER_SUCCESS: {
      const details: ICustomerDetails = action.response?.data
      return { ...state, details }
    }

    case actionTypes.GET_CUSTOMERS_ROLES_SUCCESS: {
      const roles: ICustomerRole[] = action.response?.data
      return {
        ...state,
        roles
      }
    }

    default:
      return state
  }
}

export default customersReducer
