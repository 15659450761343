import React from 'react'
import { createStyles, Switch as MuiSwitch, SwitchClassKey, SwitchProps, withStyles } from '@material-ui/core'

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string
}

interface Props extends SwitchProps {
  classes: Styles
}

const Switch = withStyles(() =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: 0
    },
    switchBase: {
      margin: 1,
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        '& + $track': {
          backgroundColor: 'white',
          opacity: 1
        }
      },
      '&$focusVisible $thumb': {},
      '&$checked $thumb': {
        color: '#B6F573'
      }
    },
    thumb: {
      width: 22,
      height: 22,
      color: '#fd756d'
    },
    track: {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,.1) inset',
      borderRadius: 26 / 2,
      border: `1px solid #aaa`,
      backgroundColor: 'white',
      opacity: 1
    },
    checked: {},
    focusVisible: {}
  })
)(({ classes, ...props }: Props) => {
  return (
    <MuiSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  )
})

export default Switch
