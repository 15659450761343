import {
  ICustomer,
  ICustomerDetails,
  TableSort,
  ICustomersFilter,
  ICustomerRole,
  ISimpleCustomerListItem,
  ICreateCustomerPayload,
  IPaginationResponse,
  ICustomerSimple
} from 'src/shared/interfaces'
import { generatePaginationPayload } from 'src/lib/paginationPayload'

import Http from './api'

export const getAllCustomers = (
  filter: ICustomersFilter,
  sort: TableSort<ICustomer> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      customer_role_ids: [filter.customer_role_ids],
      without_account: filter.without_account,
      status: filter.status
    },
    searchBy: ['customer', 'company_id'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<ICustomer>>('/customers', payload)
}

export const getCustomerRoles = () => {
  return Http.get<ICustomerRole[]>(`/customer-roles`)
}

export const getElectricityCustomers = () => {
  return Http.get<ISimpleCustomerListItem[]>(`/customers/electricity-customers`)
}

export const getAllPartners = () => {
  return Http.get<ISimpleCustomerListItem[]>(`/customers/all-partners`)
}

export const getCustomerDetail = (id: number) => {
  return Http.get<ICustomerDetails>(`/customers/${id}`)
}

export const getCustomersWithoutAccount = () => {
  return Http.get<ICustomerSimple[]>('/customers/without-account')
}

export const updateCustomerDetail = (id: number, payload: ICustomerDetails) => {
  return Http.patch<ICustomerDetails>(`/customers/${id}`, payload)
}

export const reloadCompanyInfo = (id: number) => {
  return Http.post<ICustomerDetails>(`/customers/reload-company-info/${id}`)
}

export const createCustomer = (payload: ICreateCustomerPayload) => {
  return Http.post<ICustomerDetails>('/customers/create', payload)
}

export const createUserForCustomer = (customerId: number) => {
  return Http.post<ICustomer>(`/customers/${customerId}/create-user`)
}
