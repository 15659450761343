import { AnyAction } from 'redux'

import {
  IConsumption,
  IConsumptionForEic,
  IContract,
  IContractDetails,
  IContractStatus,
  IEic,
  IEicDetails,
  IInvoice,
  IPaginationResponse,
  ITraderContractsState
} from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: ITraderContractsState = {
  consumption: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  eicList: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  invoicesList: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  statuses: [],
  details: undefined,
  eicDetails: undefined,
  consumptionDetails: undefined
}

const tradersReducer = (state = initialState, action: AnyAction): ITraderContractsState => {
  switch (action.type) {
    case actionTypes.GET_TRADER_DRAFTS_CONTRACTS_SUCCESS:
    case actionTypes.GET_DRAFTS_CONTRACTS_SUCCESS:
    case actionTypes.GET_TRADER_CONTRACTS_SUCCESS: {
      const response: IPaginationResponse<IContract> = action.response?.data
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length ? state.list.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.UPDATE_TRADER_CONTRACT_DETAIL_SUCCESS:
    case actionTypes.UPDATE_CONTRACT_DETAIL_SUCCESS:
    case actionTypes.GET_TRADER_CONTRACT_DETAIL_SUCCESS:
    case actionTypes.GET_CONTRACT_DETAIL_SUCCESS:
    case actionTypes.TRADER_POST_CONTRACT_DELIVERY_POINTS_SUCCESS:
    case actionTypes.CREATE_DRAFT_CONTRACT_SUCCESS:
    case actionTypes.CREATE_TRADER_CONTRACT_SUCCESS: {
      const details: IContractDetails = action.response?.data
      return { ...state, details }
    }

    case actionTypes.GET_CONTRACTS_STATUSES_SUCCESS:
    case actionTypes.GET_TRADER_CONTRACTS_STATUSES_SUCCESS: {
      const statuses: IContractStatus[] = action.response?.data
      return {
        ...state,
        // eslint-disable-next-line fp/no-mutating-methods
        statuses: statuses?.sort((statusA, statusB) => statusA.id - statusB.id)
      }
    }

    case actionTypes.GET_TRADER_EIC_SUCCESS: {
      const response: IPaginationResponse<IEic> = action.response?.data?.paginated
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length ? state.eicList.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        eicList: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_TRADER_CONSUMPTIONS_SUCCESS: {
      const response: IPaginationResponse<IConsumption> = action.response?.data
      const totalCount = Object.keys(action.payload.filter).length
        ? state.consumption.totalCount
        : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        consumption: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_TRADER_ALL_INVOICES_SUCCESS: {
      const response: IPaginationResponse<IInvoice> = action.response?.data
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length
        ? state.invoicesList.totalCount
        : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        invoicesList: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_EIC_DETAIL_BY_TRADER_SUCCESS: {
      const eicDetails: IEicDetails = action.response?.data
      return { ...state, eicDetails }
    }

    case actionTypes.GET_CONSUMPTION_BY_EIC_BY_TRADER_SUCCESS: {
      const consumptionDetails: IConsumptionForEic[] = action.response?.data
      return { ...state, consumptionDetails }
    }

    default:
      return state
  }
}

export default tradersReducer
