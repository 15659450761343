import { AnyAction } from 'redux'
import { ITradingState } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState = {
  volumes: []
}

const tradingReducer = (state = initialState, action: AnyAction): ITradingState => {
  switch (action.type) {
    case actionTypes.GET_VOLUMES_TRIGGERED: {
      return { ...state, volumes: initialState.volumes }
    }

    case actionTypes.GET_VOLUMES_SUCCESS: {
      return { ...state, volumes: action.response?.data }
    }

    default:
      return state
  }
}

export default tradingReducer
