import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'

interface PriceItemStatusProps {
  value?: number | string
}

const PriceItemStatus = (props: PriceItemStatusProps) => {
  const { t } = useTranslation()

  if (!props.value) return null

  const status = [
    { id: 1, name: 'ACTIVE' },
    { id: 2, name: 'INACTIVE' }
  ].find(priceItemStatus => priceItemStatus.id === Number(props.value))
  let color

  switch (Number(status?.id)) {
    case 1:
      color = '#8abd25'
      break
    case 2:
      color = '#ff3b30'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`PRICE_ITEM_STATUS.${status?.name}` as never)}</span>
    </Container>
  )
}

PriceItemStatus.displayName = 'PriceItemStatus'
export default PriceItemStatus
