import styled from 'styled-components'
import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableSortLabel as MuiTableSortLabel
} from '@material-ui/core'
import { Paper, Button as SButton } from '@otion-core/sandy'

const TableWrapper = styled(Paper)`
  flex: 1;
  width: 100%;
  padding: 0 26px 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
`

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgb(255 255 255 / 70%);
`

const ScrollButton = styled(SButton)<{ arrow: string; hide: string }>`
  width: 50px;
  height: 50px !important;
  border-radius: 50% !important;
  padding: 0 !important;
  position: absolute !important;
  transition: opacity ease 0.2s;
  border: none !important;
  z-index: 2;
  ${({ arrow }) =>
    arrow === 'left' &&
    `
    left: -15px;
    top: calc(50% - 25px);
    
    svg {
      transform: rotate(90deg);
    }
  `}
  ${({ arrow }) =>
    arrow === 'right' &&
    `
    right: -15px;
    top: calc(50% - 25px);
    
    svg {
      transform: rotate(-90deg);
    }
  `}
  ${({ hide }) => (hide ? 'opacity: 0;' : '')}
`

const TableScrollWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;

  th {
    font-size: 13px !important;
  }

  td,
  td * {
    font-size: 12px !important;
    font-family: 'Azeret Mono', monospace !important;
    font-weight: 400;
    letter-spacing: -0.01rem;
  }

  div {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const TableHeadCell = styled(MuiTableCell)`
  padding: 10px 8px !important;
  line-height: 1.5 !important;
  position: relative;

  &:first-child,
  &:last-child {
    border-right: none !important;
  }
`

const TableCell = styled(MuiTableCell)`
  overflow: hidden;
  padding: 6px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TableRow = styled(MuiTableRow)`
  cursor: pointer;

  &:nth-child(2n) {
    background-color: rgb(0 0 0 / 2.5%);
  }

  /* stylelint-disable selector-class-pattern */
  &.Mui-selected {
    background-color: rgb(0 50 150 / 7%) !important;

    &:hover {
      background-color: rgb(0 30 80 / 12%) !important;
    }
  }
  /* stylelint-enable selector-class-pattern */

  .ghost-checkbox {
    display: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: rgb(0 0 0 / 6%) !important;

    .id-counter {
      display: none;
    }

    .ghost-checkbox {
      display: inline-block;
    }
  }
`

const TableContainer = styled(MuiTableContainer)`
  overflow-x: initial !important;
`

const TableSortLabel = styled(MuiTableSortLabel)`
  justify-content: space-between !important;
  width: 100%;

  /* stylelint-disable selector-class-pattern */
  .MuiTableSortLabel-icon {
    display: none !important;
  }
  /* stylelint-enable selector-class-pattern */

  .sort-icon {
    margin-left: 5px;
    display: flex;

    svg {
      height: 20px;
    }

    &.asc {
      .sort-path-asc {
        fill: #242424;
      }

      .sort-path-desc {
        fill: #e0e0e0;
      }
    }

    &.desc {
      .sort-path-asc {
        fill: #e0e0e0;
      }

      .sort-path-desc {
        fill: #242424;
      }
    }
  }
`

const ResizeLine = styled.div`
  position: absolute;
  height: 100%;
  width: 7px;
  top: 0;
  right: 0;
  cursor: col-resize;
  display: flex;
  justify-content: center;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background: #f0f0f0;
    transition: all 200ms;
  }

  &:hover,
  &:active {
    &::before {
      background-color: #aaa;
      width: 3px;
    }
  }
`

export {
  TableWrapper,
  TableScrollWrapper,
  ScrollButton,
  TableRow,
  TableHeadCell,
  TableCell,
  TableContainer,
  TableSortLabel,
  LoadingContainer,
  ResizeLine
}
