import { AnyAction } from 'redux'

import {
  IClientsState,
  IEic,
  IInvoice,
  IPaginationResponse,
  IConsumption,
  IEicDetails,
  IConsumptionForEic
} from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IClientsState = {
  consumption: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  spotPrices: [],
  eicList: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  invoicesList: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  eicDetails: undefined,
  consumptionDetails: undefined
}

const clientsReducer = (state = initialState, action: AnyAction): IClientsState => {
  switch (action.type) {
    case actionTypes.GET_CLIENTS_SPOT_PRICES_TRIGGERED: {
      return { ...state, spotPrices: initialState.spotPrices }
    }
    case actionTypes.GET_TRADER_SPOT_PRICES_SUCCESS:
    case actionTypes.GET_CLIENTS_SPOT_PRICES_SUCCESS: {
      return { ...state, spotPrices: action.response?.data }
    }

    case actionTypes.GET_CLIENTS_CONSUMPTIONS_SUCCESS: {
      const response: IPaginationResponse<IConsumption> = action.response?.data
      const totalCount = Object.keys(action.payload.filter).length
        ? state.consumption.totalCount
        : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        consumption: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_CLIENTS_EIC_SUCCESS: {
      const response: IPaginationResponse<IEic> = action.response?.data?.paginated
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length ? state.eicList.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        eicList: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_CLIENTS_INVOICES_SUCCESS: {
      const response: IPaginationResponse<IInvoice> = action.response?.data
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length
        ? state.invoicesList.totalCount
        : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        invoicesList: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_EIC_DETAIL_BY_CLIENT_SUCCESS: {
      const eicDetails: IEicDetails = action.response?.data
      return { ...state, eicDetails }
    }

    case actionTypes.GET_CONSUMPTION_BY_EIC_BY_CLIENT_SUCCESS: {
      const consumptionDetails: IConsumptionForEic[] = action.response?.data
      return { ...state, consumptionDetails }
    }

    default:
      return state
  }
}

export default clientsReducer
