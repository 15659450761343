import { ICustomer } from './customers.interface'
import { IPaginationList } from './common.interface'

// eslint-disable-next-line no-shadow
export enum UserRole {
  ADMIN = 'Admin',
  CLIENT = 'Client',
  TRADER = 'Trader'
}

export interface IUser {
  id: number
  first_name: string
  last_name: string
  email: string
  phone: string
  is_active: boolean
  department: string
  last_login_at: string
  role: UserRole
  customers: ICustomer[]
}
export interface IUserDetails {
  id: number
  first_name: string
  last_name: string
  email: string
  phone: string
  is_active: boolean
  department: string
  last_login_at: string
  created_at: string
  role: UserRole
  customers: ICustomer[]
}

export interface IUsersFilter {
  search?: string
  role?: UserRole
  isActive?: boolean
}

export interface IChangePassword {
  old_password: string
  new_password: string
}

export interface ICreateUserPayload {
  first_name: string
  last_name: string
  email: string
  phone: string
  password: string
  role: string
  repeatPassword?: string
  isRandomPassword?: 'false' | 'true' | boolean
  customers?: number[]
}

export interface IUpdateUserPayload {
  is_active?: boolean
  first_name?: string
  last_name?: string
  email?: string
  phone?: string
  department?: number
}

export interface IUpdateTraderPayload {
  trader_id?: string
}

export interface IUsersState {
  list: IPaginationList<IUser>
  details?: IUserDetails
  traders?: IUser[]
}

export interface IAssignCustomerPayload {
  user_id: string
  customer_id: string
}
