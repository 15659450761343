import React from 'react'
import { Sandy, EnvironmentPanel } from '@otion-core/sandy'
import preval from 'preval.macro'

import { ErrorBoundary } from './components'
import AppRouter from './router'
import resolveVersion from './lib/resolveVersion'
import customTheme from './customTheme'
import GlobalStyle from './global.styles'

const App = () => (
  <Sandy
    appName={process.env.REACT_APP_NAME}
    nodeEnv={process.env.NODE_ENV}
    customEnv={process.env.REACT_APP_ENV_NAME}
    appVersion={resolveVersion()}
    builtAt={new Date(preval`module.exports = new Date()`)}
    sentryEnabled={process.env.REACT_APP_SENTRY_ENABLED === 'true'}
    sentryDSN={process.env.REACT_APP_SENTRY_DSN}
    themeOverrides={customTheme}
  >
    <ErrorBoundary>
      <GlobalStyle />
      <AppRouter />
    </ErrorBoundary>
    {process.env.REACT_APP_ENV_NAME !== 'production' && <EnvironmentPanel />}
  </Sandy>
)

App.displayName = 'App'
export default App
