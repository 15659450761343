export interface IConsumption {
  date: string
  eic_address_city: string
  eic_address_number: string
  eic_address_street: string
  eic_address_zip_code: string
  eic_competition: string
  eic_created_at: string
  eic_created_by: string
  eic_delivery_end: string
  eic_delivery_start: string
  eic_distribution_system: string
  eic_eic: string
  eic_id: number
  eic_notice_period_for_termination_months: number
  eic_number_of_phases: number
  eic_rate: string
  eic_reserved_capacity: string
  eic_status: number
  eic_tariffing: string
  eic_type_of_measurement: string
  eic_updated_at: string
  eic_updated_by: string
  eic_voltage_level: string
  id: number
  intervals: number[]
  max_consumption: string
}

export interface IConsumptionForEic {
  date: string
  eic_id: number
  id: number
  intervals: number[]
  max_consumption: string
}

export interface IConsumptionFilter {
  search?: string
  delivery_start_date_from?: string
  delivery_start_date_to?: string
  delivery_end_date_from?: string
  delivery_end_date_to?: string
  status?: number
}

export interface IGetConsumptionByEicPayload {
  date_start: string
  date_end: string
}

// eslint-disable-next-line no-shadow
export enum ConsumptionAverageType {
  DAY = 'day'
}
