import React, { ReactNode } from 'react'
import { ErrorBoundary as SandyErrorBoundary } from '@otion-core/sandy'

export interface ErrorBoundaryProps {
  children?: ReactNode
}

const ErrorBoundary = ({ children, ...rest }: ErrorBoundaryProps) => {
  return (
    <SandyErrorBoundary email='appcrash@encare.sk' {...rest}>
      {children}
    </SandyErrorBoundary>
  )
}

ErrorBoundary.displayName = 'ErrorBoundary'
export default ErrorBoundary
