import { IWarning, IWarningFilter, PromiseAction, TableSort } from '../../shared/interfaces'
import * as WarningsApi from '../../apis/warnings.api'

export const GET_WARNINGS_TRIGGERED = 'GET_WARNINGS_TRIGGERED'
export const GET_WARNINGS_SUCCESS = 'GET_WARNINGS_SUCCESS'
export const GET_WARNINGS_FAILURE = 'GET_WARNINGS_FAILURE'

export function getWarnings(
  filter: IWarningFilter,
  sort: TableSort<IWarning> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = WarningsApi.getWarnings(filter, sort, page, size)
  return {
    onRequest: GET_WARNINGS_TRIGGERED,
    onSuccess: GET_WARNINGS_SUCCESS,
    onFailure: GET_WARNINGS_FAILURE,
    promise,
    payload: { filter, page, size }
  }
}

export const GET_WARNING_TYPES_TRIGGERED = 'GET_WARNING_TYPES_TRIGGERED'
export const GET_WARNING_TYPES_SUCCESS = 'GET_WARNING_TYPES_SUCCESS'
export const GET_WARNING_TYPES_FAILURE = 'GET_WARNING_TYPES_FAILURE'

export function getWarningTypes(): PromiseAction {
  const promise = WarningsApi.getWarningTypes()
  return {
    onRequest: GET_WARNING_TYPES_TRIGGERED,
    onSuccess: GET_WARNING_TYPES_SUCCESS,
    onFailure: GET_WARNING_TYPES_FAILURE,
    promise
  }
}
