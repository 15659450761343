import * as TradingApi from '../../apis/trading.api'
import { IGetVolumesPayload, PromiseAction } from '../../shared/interfaces'

export const GET_VOLUMES_TRIGGERED = 'GET_VOLUMES_TRIGGERED'
export const GET_VOLUMES_SUCCESS = 'GET_VOLUMES_SUCCESS'
export const GET_VOLUMES_FAILURE = 'GET_VOLUMES_FAILURE'

export const getVolumes = (payload: IGetVolumesPayload): PromiseAction => {
  const promise = TradingApi.getVolumes(payload)
  return {
    onRequest: GET_VOLUMES_TRIGGERED,
    onSuccess: GET_VOLUMES_SUCCESS,
    onFailure: GET_VOLUMES_FAILURE,
    promise
  }
}
