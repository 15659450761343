import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Icon, Typography } from '@otion-core/sandy'

import { EditIcon } from 'src/assets/icons'

import * as S from './styles'

interface FilterMenuProps {
  name?: string
  totalCount?: number
  filteredCount?: number
  monthlyCount?: number[]
  applyFilter: (filter: unknown) => void
  initialFilter?: unknown
}

const monthNames = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
]

const FilterBar = (props: FilterMenuProps) => {
  const { name = 'CONTRACTS', totalCount, filteredCount, monthlyCount } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedFilterOption, setSelectedFilterOption] = useState('all')
  const [showCurrentYearAll, setShowCurrentYearAll] = useState(false)
  const [showLastYearAll, setShowLastYearAll] = useState(false)

  useEffect(() => {
    const search = location.search
    if (search === '') {
      setSelectedFilterOption('all')
    } else {
      if (search.indexOf('time=last_year') > -1) {
        setSelectedFilterOption('last_year')
      } else if (search.indexOf('time=current_year') > -1) {
        setSelectedFilterOption('current_year')
      } else {
        setSelectedFilterOption('')
      }
    }
  }, [location])

  const onFilterOptionChange = (option: string) => {
    setSelectedFilterOption(option)
    let filterQuery
    if (option === 'all') {
      filterQuery = ''
    } else if (option === 'last_year') {
      filterQuery = `?time=last_year`
    } else if (option === 'current_year') {
      filterQuery = `?time=current_year`
    } else {
      filterQuery = ''
    }
    setShowCurrentYearAll(false)
    setShowLastYearAll(false)
    navigate(location.pathname + filterQuery)
  }

  return (
    <S.Container>
      <Container flex style={{ maxWidth: '230px' }} bottom={2}>
        <S.FilterButton onClick={() => null} variant='outlined' color='blue' stopPropagation fullWidth={false}>
          <Icon name='filter' color='blue' />
        </S.FilterButton>
        <S.Input placeholder={t('ENTER_NAME')} icon='search' iconPosition='left' style={{ marginLeft: '20px' }} />
      </Container>
      <Typography size='medium'>
        {filteredCount || 0}/{totalCount || 0}
      </Typography>
      <S.FilterOptions>
        <S.Button
          variant='outlined'
          size='xsmall'
          selected={selectedFilterOption === 'all'}
          style={{ justifyContent: 'flex-start' }}
          onClick={() => onFilterOptionChange('all')}
        >
          {t('ALL_ITEMS')}
        </S.Button>
        {selectedFilterOption === 'last_year' ? (
          <S.ContactsInfoList background='blue'>
            <div className='header'>
              <Typography size='medium' color='white'>{`${t(name as never)} - ${t('LAST_YEAR')}`}</Typography>
              <EditIcon height='30px' fill='#ffffff' />
            </div>
            {monthlyCount &&
              monthNames
                .filter((_, index) => showLastYearAll || index < 4)
                .map((monthName, index) => (
                  <S.ContactsListItem key={monthName}>
                    <Typography size='medium'>{t(`MONTH.${monthName}` as never)}</Typography>
                    <Typography size='medium'>{monthlyCount[index]}</Typography>
                  </S.ContactsListItem>
                ))}
            {monthlyCount && monthlyCount.length > 0 && !showLastYearAll && (
              <div className='footer' onClick={() => setShowLastYearAll(true)}>
                <Typography size='medium' color='white' align='center'>
                  {t('FILTER.VIEW_ALL')}
                </Typography>
              </div>
            )}
          </S.ContactsInfoList>
        ) : (
          <S.Button
            variant='outlined'
            size='xsmall'
            style={{ justifyContent: 'flex-start' }}
            onClick={() => onFilterOptionChange('last_year')}
          >
            {`${t(name as never)} - ${t('LAST_YEAR')}`}
          </S.Button>
        )}
        {selectedFilterOption === 'current_year' ? (
          <S.ContactsInfoList background='blue'>
            <div className='header'>
              <Typography size='medium' color='white'>{`${t(name as never)} - ${t('CURRENT_YEAR')}`}</Typography>
              <EditIcon height='30px' fill='#ffffff' />
            </div>
            {monthlyCount &&
              monthNames
                .filter((_, index) => showCurrentYearAll || index < 4)
                .map((monthName, index) => (
                  <S.ContactsListItem key={monthName}>
                    <Typography size='medium'>{t(`MONTH.${monthName}` as never)}</Typography>
                    <Typography size='medium'>{monthlyCount[index]}</Typography>
                  </S.ContactsListItem>
                ))}
            {monthlyCount && monthlyCount.length > 0 && !showCurrentYearAll && (
              <div className='footer' onClick={() => setShowCurrentYearAll(true)}>
                <Typography size='medium' color='white' align='center'>
                  {t('FILTER.VIEW_ALL')}
                </Typography>
              </div>
            )}
          </S.ContactsInfoList>
        ) : (
          <S.Button
            variant='outlined'
            size='xsmall'
            style={{ justifyContent: 'flex-start' }}
            onClick={() => onFilterOptionChange('current_year')}
          >
            {`${t(name as never)} - ${t('CURRENT_YEAR')}`}
          </S.Button>
        )}
      </S.FilterOptions>
    </S.Container>
  )
}

FilterBar.displayName = 'FilterBar'
export default FilterBar
