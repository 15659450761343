import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { EicStatusEnum } from 'src/shared/interfaces'

interface EicStatusProps {
  value?: EicStatusEnum
}

const EicStatus = (props: EicStatusProps) => {
  const { t } = useTranslation()

  if (!props.value) return null

  let color

  switch (props.value) {
    case EicStatusEnum.NEW:
      color = '#e6e6e6'
      break
    case EicStatusEnum.PROCESSING:
      color = '#f1e0a7'
      break
    case EicStatusEnum.WAITING_FOR_DELIVERY:
      color = '#f3d163'
      break
    case EicStatusEnum.ONGOING_CONNECTION:
      color = '#ffa300'
      break
    case EicStatusEnum.UNSUCCESSFUL_CONNECTION:
      color = '#ff3b30'
      break
    case EicStatusEnum.ACTIVE_DELIVERY:
      color = '#8abd25'
      break
    case EicStatusEnum.DISCONNECTED_DISTRIBUTION:
      color = '#ff3b30'
      break
    case EicStatusEnum.ONGOING_TERMINATION:
      color = '#ff3b30'
      break
    case EicStatusEnum.TERMINATED_DELIVERY:
      color = '#424242'
      break
    case EicStatusEnum.DRAFT:
      color = '#ddd'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`EIC_STATUS.${props.value}` as never)}</span>
    </Container>
  )
}

EicStatus.displayName = 'EicStatus'
export default EicStatus
