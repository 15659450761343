// eslint-disable-next-line no-shadow
export enum WARNING_TYPE {
  FAILED_WRITE_DB = 1,
  INVOICE,
  PROFORMA_INVOICE,
  PAYMENT_INVOICE,
  MAILBOX,
  CONTRACT,
  FAILED_910_MESSAGE
}

export interface IWarning {
  id: number
  warning_type_id: WARNING_TYPE
  event: string // JSON string
  created_at: string
}

export interface IWarningFilter {
  warning_type_id?: number
}
