import styled from 'styled-components'
import { Input as SInput, Modal as SModal } from '@otion-core/sandy/build/components'

interface ConnectionItemProps {
  active: boolean
}

const List = styled.div`
  display: flex;
  flex-direction: column;
`

const ListItem = styled.div<ConnectionItemProps>`
  border-top: 1px solid #c5c7c8;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  cursor: pointer;

  :first-child {
    border-top: none;
  }

  ${props =>
    props.active &&
    `
    p {
      font-weight: 700 !important;
    }
  `}
`

const AddConnectionModal = styled(SModal)`
  > div > div {
    max-width: 400px !important;

    > div {
      padding: 20px 26px !important;
    }
  }
`

const Input = styled(SInput)`
  input {
    line-height: 38px !important;
    padding: 0 20px !important;
  }
`

export { AddConnectionModal, ListItem, List, Input }
