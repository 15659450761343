import { useState } from 'react'

interface UseQuickFilterProps {
  applyFilter: (payload: any) => void
}

function useQuickFilters<FilterValues = any>({ applyFilter }: UseQuickFilterProps) {
  const [selectedFilterOption, setSelectedFilterOption] = useState<'all' | string>('all')

  const onFilterOptionChange = (filterName: string, filterValues?: FilterValues, resetForm?: () => void) => {
    setSelectedFilterOption(filterName)
    if (filterName === 'all') applyFilter({} as FilterValues)
    else applyFilter(filterValues as FilterValues)
    resetForm?.()
  }

  return { selectedFilterOption, onFilterOptionChange }
}

export default useQuickFilters
