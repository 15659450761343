import * as InvoicesApi from '../../apis/invoices.api'
import { IInvoice, IInvoicesFilter, IPostGenerateInvoices, PromiseAction, TableSort } from '../../shared/interfaces'

// Get paginated invoices
export const GET_ALL_INVOICES_TRIGGERED = 'GET_ALL_INVOICES_TRIGGERED'
export const GET_ALL_INVOICES_SUCCESS = 'GET_ALL_INVOICES_SUCCESS'
export const GET_ALL_INVOICES_FAILURE = 'GET_ALL_INVOICES_FAILURE'

export function getAllInvoices(
  filter: IInvoicesFilter,
  sort: TableSort<IInvoice> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = InvoicesApi.getAllInvoices(filter, sort, page, size)
  return {
    onRequest: GET_ALL_INVOICES_TRIGGERED,
    onSuccess: GET_ALL_INVOICES_SUCCESS,
    onFailure: GET_ALL_INVOICES_FAILURE,
    promise,
    payload: { filter, page, size }
  }
}

// Get invoice details
export const GET_INVOICE_DETAILS_TRIGGERED = 'GET_INVOICE_DETAILS_TRIGGERED'
export const GET_INVOICE_DETAILS_SUCCESS = 'GET_INVOICE_DETAILS_SUCCESS'
export const GET_INVOICE_DETAILS_FAILURE = 'GET_INVOICE_DETAILS_FAILURE'

export function getInvoiceDetails(id: number): PromiseAction {
  const promise = InvoicesApi.getInvoiceDetails(id)
  return {
    onRequest: GET_INVOICE_DETAILS_TRIGGERED,
    onSuccess: GET_INVOICE_DETAILS_SUCCESS,
    onFailure: GET_INVOICE_DETAILS_FAILURE,
    promise
  }
}

// Update invoice details
export const UPDATE_INVOICE_DETAILS_TRIGGERED = 'UPDATE_INVOICE_DETAILS_TRIGGERED'
export const UPDATE_INVOICE_DETAILS_SUCCESS = 'UPDATE_INVOICE_DETAILS_SUCCESS'
export const UPDATE_INVOICE_DETAILS_FAILURE = 'UPDATE_INVOICE_DETAILS_FAILURE'

export function updateInvoiceDetails(id: number, payload: Partial<IInvoice>): PromiseAction {
  const promise = InvoicesApi.updateInvoiceDetails(id, payload)
  return {
    onRequest: UPDATE_INVOICE_DETAILS_TRIGGERED,
    onSuccess: UPDATE_INVOICE_DETAILS_SUCCESS,
    onFailure: UPDATE_INVOICE_DETAILS_FAILURE,
    promise
  }
}

// Download pdf invoice
export const GET_PDF_INVOICE_TRIGGERED = 'GET_PDF_INVOICE_TRIGGERED'
export const GET_PDF_INVOICE_SUCCESS = 'GET_PDF_INVOICE_SUCCESS'
export const GET_PDF_INVOICE_FAILURE = 'GET_PDF_INVOICE_FAILURE'

export function getPdfInvoice(id: number): PromiseAction {
  const promise = InvoicesApi.getPdfInvoice(id)
  return {
    onRequest: GET_PDF_INVOICE_TRIGGERED,
    onSuccess: GET_PDF_INVOICE_SUCCESS,
    onFailure: GET_PDF_INVOICE_FAILURE,
    promise
  }
}

// Preview pdf invoice
export const PREVIEW_PDF_INVOICE_TRIGGERED = 'PREVIEW_PDF_INVOICE_TRIGGERED'
export const PREVIEW_PDF_INVOICE_SUCCESS = 'PREVIEW_PDF_INVOICE_SUCCESS'
export const PREVIEW_PDF_INVOICE_FAILURE = 'PREVIEW_PDF_INVOICE_FAILURE'

export function getPdfInvoicePreview(id: number): PromiseAction {
  const promise = InvoicesApi.getPdfInvoicePreview(id)
  return {
    onRequest: PREVIEW_PDF_INVOICE_TRIGGERED,
    onSuccess: PREVIEW_PDF_INVOICE_SUCCESS,
    onFailure: PREVIEW_PDF_INVOICE_FAILURE,
    promise
  }
}

// Generate Proforma Invoices for current month
export const POST_GENERATE_PROFORMA_INVOICES_TRIGGERED = 'POST_GENERATE_PROFORMA_INVOICES_TRIGGERED'
export const POST_GENERATE_PROFORMA_INVOICES_SUCCESS = 'POST_GENERATE_PROFORMA_INVOICES_SUCCESS'
export const POST_GENERATE_PROFORMA_INVOICES_FAILURE = 'POST_GENERATE_PROFORMA_INVOICES_FAILURE'

export function postGenerateProformaInvoices(payload: IPostGenerateInvoices): PromiseAction {
  const promise = InvoicesApi.generateProformaInvoices(payload)
  return {
    onRequest: POST_GENERATE_PROFORMA_INVOICES_TRIGGERED,
    onSuccess: POST_GENERATE_PROFORMA_INVOICES_SUCCESS,
    onFailure: POST_GENERATE_PROFORMA_INVOICES_FAILURE,
    promise,
    payload: { payload }
  }
}

// Generate Payment Invoices for current month
export const POST_GENERATE_PAYMENT_INVOICES_TRIGGERED = 'POST_GENERATE_PAYMENT_INVOICES_TRIGGERED'
export const POST_GENERATE_PAYMENT_INVOICES_SUCCESS = 'POST_GENERATE_PAYMENT_INVOICES_SUCCESS'
export const POST_GENERATE_PAYMENT_INVOICES_FAILURE = 'POST_GENERATE_PAYMENT_INVOICES_FAILURE'

export function postGeneratePaymentInvoices(payload: IPostGenerateInvoices): PromiseAction {
  const promise = InvoicesApi.generatePaymentInvoices(payload)
  return {
    onRequest: POST_GENERATE_PAYMENT_INVOICES_TRIGGERED,
    onSuccess: POST_GENERATE_PAYMENT_INVOICES_SUCCESS,
    onFailure: POST_GENERATE_PAYMENT_INVOICES_FAILURE,
    promise,
    payload: { payload }
  }
}

// Create Invoice For VFA
export const POST_CREATE_INVOICE_FOR_VFA_TRIGGERED = 'POST_CREATE_INVOICE_FOR_VFA_TRIGGERED'
export const POST_CREATE_INVOICE_FOR_VFA_SUCCESS = 'POST_CREATE_INVOICE_FOR_VFA_SUCCESS'
export const POST_CREATE_INVOICE_FOR_VFA_FAILURE = 'POST_CREATE_INVOICE_FOR_VFA_FAILURE'

export function postCreateInvoiceForVfa(id: number): PromiseAction {
  const promise = InvoicesApi.createInvoiceForVfa(id)
  return {
    onRequest: POST_CREATE_INVOICE_FOR_VFA_TRIGGERED,
    onSuccess: POST_CREATE_INVOICE_FOR_VFA_SUCCESS,
    onFailure: POST_CREATE_INVOICE_FOR_VFA_FAILURE,
    promise
  }
}

// Send selected invoice by email
export const SEND_INVOICE_TRIGGERED = 'SEND_INVOICE_TRIGGERED'
export const SEND_INVOICE_SUCCESS = 'SEND_INVOICE_SUCCESS'
export const SEND_INVOICE_FAILURE = 'SEND_INVOICE_FAILURE'

export function sendInvoice(id: number): PromiseAction {
  const promise = InvoicesApi.sendInvoice(id)
  return {
    onRequest: SEND_INVOICE_TRIGGERED,
    onSuccess: SEND_INVOICE_SUCCESS,
    onFailure: SEND_INVOICE_FAILURE,
    promise
  }
}

// Force issue selected draft invoice
export const FORCE_ISSUE_INVOICE_TRIGGERED = 'FORCE_ISSUE_INVOICE_TRIGGERED'
export const FORCE_ISSUE_INVOICE_SUCCESS = 'FORCE_ISSUE_INVOICE_SUCCESS'
export const FORCE_ISSUE_INVOICE_FAILURE = 'FORCE_ISSUE_INVOICE_FAILURE'

export function forceIssueInvoice(id: number): PromiseAction {
  const promise = InvoicesApi.forceIssueInvoice(id)
  return {
    onRequest: FORCE_ISSUE_INVOICE_TRIGGERED,
    onSuccess: FORCE_ISSUE_INVOICE_SUCCESS,
    onFailure: FORCE_ISSUE_INVOICE_FAILURE,
    promise
  }
}

// Cancel selected invoice
export const CANCEL_INVOICE_TRIGGERED = 'CANCEL_INVOICE_TRIGGERED'
export const CANCEL_INVOICE_SUCCESS = 'CANCEL_INVOICE_SUCCESS'
export const CANCEL_INVOICE_FAILURE = 'CANCEL_INVOICE_FAILURE'

export function cancelInvoice(id: number): PromiseAction {
  const promise = InvoicesApi.cancelInvoice(id)
  return {
    onRequest: CANCEL_INVOICE_TRIGGERED,
    onSuccess: CANCEL_INVOICE_SUCCESS,
    onFailure: CANCEL_INVOICE_FAILURE,
    promise
  }
}

// Reload customer info, regenerate invoice
export const REGENERATE_INVOICE_TRIGGERED = 'REGENERATE_INVOICE_TRIGGERED'
export const REGENERATE_INVOICE_SUCCESS = 'REGENERATE_INVOICE_SUCCESS'
export const REGENERATE_INVOICE_FAILURE = 'REGENERATE_INVOICE_FAILURE'

export function regenerateInvoice(id: number): PromiseAction {
  const promise = InvoicesApi.regenerateInvoice(id)
  return {
    onRequest: REGENERATE_INVOICE_TRIGGERED,
    onSuccess: REGENERATE_INVOICE_SUCCESS,
    onFailure: REGENERATE_INVOICE_FAILURE,
    promise
  }
}

export const PAIR_BANK_ACCOUNTS_TRIGGERED = 'PAIR_BANK_ACCOUNTS_TRIGGERED'
export const PAIR_BANK_ACCOUNTS_SUCCESS = 'PAIR_BANK_ACCOUNTS_SUCCESS'
export const PAIR_BANK_ACCOUNTS_FAILURE = 'PAIR_BANK_ACCOUNTS_FAILURE'

export function pairBankAccounts(): PromiseAction {
  const promise = InvoicesApi.tryToPairBankAccounts()
  return {
    onRequest: PAIR_BANK_ACCOUNTS_TRIGGERED,
    onSuccess: PAIR_BANK_ACCOUNTS_SUCCESS,
    onFailure: PAIR_BANK_ACCOUNTS_FAILURE,
    promise
  }
}

// Export invoices list in xls
export const EXPORT_INVOICES_TRIGGERED = 'EXPORT_INVOICES_TRIGGERED'
export const EXPORT_INVOICES_SUCCESS = 'EXPORT_INVOICES_SUCCESS'
export const EXPORT_INVOICES_FAILURE = 'EXPORT_INVOICES_FAILURE'

export function exportInvoices(filter: IInvoicesFilter): PromiseAction {
  const promise = InvoicesApi.exportInvoices(filter)
  return {
    onRequest: EXPORT_INVOICES_TRIGGERED,
    onSuccess: EXPORT_INVOICES_SUCCESS,
    onFailure: EXPORT_INVOICES_FAILURE,
    promise
  }
}
