import { AnyAction } from 'redux'

import { IPaginationResponse, IInvoice, IInvoicesState } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IInvoicesState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  details: undefined
}

const invoicesReducer = (state = initialState, action: AnyAction): IInvoicesState => {
  switch (action.type) {
    case actionTypes.GET_ALL_INVOICES_SUCCESS: {
      const response: IPaginationResponse<IInvoice> = action.response?.data
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length ? state.list.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_INVOICE_DETAILS_SUCCESS:
    case actionTypes.REGENERATE_INVOICE_SUCCESS: {
      return {
        ...state,
        details: action.response?.data
      }
    }

    default:
      return state
  }
}

export default invoicesReducer
