import styled from 'styled-components'
import { getBPHelpers } from '@otion-core/sandy'

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 53px;

  ${props => getBPHelpers(props.theme.breakpoints).screens('small')} {
    margin-right: 0;
  }
`

const UserName = styled.p`
  max-width: 100px;
  color: #242424;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: right !important;
  margin: 0 20px;
  max-height: 36px;
  overflow: hidden;
`

export { ContentWrapper, UserName }
