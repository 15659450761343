import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Typography, Container, Button, BasePage, Link, getBPHelpers } from '@otion-core/sandy'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import BackgroundImage from 'src/shared/images/login-background.png'
import { setToken } from 'src/utils'
import { login } from 'src/redux/actions'
import { useNotifications } from 'src/shared/hooks'
import { Error, Input } from 'src/components/commonStyled'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Nesprávny formát e-mailu')
    .min(3, 'Minimálne 3 symboly')
    .max(50, 'Maximálne 50 symbolov')
    .required('E-mail je povinný'),
  password: Yup.string()
    .min(3, 'Minimálne 3 symboly')
    .max(50, 'Maximálne 50 symbolov')
    .required('Heslo je povinné')
})

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { showError } = useNotifications()
  const themeContext = useContext(ThemeContext)
  const BPHelpers = getBPHelpers(themeContext.breakpoints)
  const isSmall = BPHelpers.useBreakpoint('small')

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true)
      dispatch(login(values))
        .then((res: any) => {
          setLoading(false)
          if (res?.data?.access_token) {
            setToken(res?.data?.access_token)
            resetForm()
            navigate('/')
          } else {
            throw res
          }
          return res
        })
        .catch((err: any) => {
          setLoading(false)
          setSubmitting(false)
          const errorMessage = Array.isArray(err?.message) ? err.message[0] : 'Error'
          showError(errorMessage)
        })
    }
  })

  return (
    <BasePage
      title='Prihlásenie'
      isLanding
      centered
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        maxWidth: '100vw'
      }}
    >
      <Container flex fullWidth alignItems='center' justifyContent='center'>
        <Container fullWidth direction='column' justifyContent='center' style={{ maxWidth: 500 }}>
          <Typography
            align='center'
            font='secondary'
            style={{ fontSize: isSmall ? 32 : 42 }}
            variant='heading'
            color='white'
          >
            Prihlásenie
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Container top='medium' bottom='medium'>
              <Input
                placeholder='E-mail'
                width='full'
                error={Boolean(formik.touched.email && formik.errors.email)}
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? <Error>{formik.errors.email}</Error> : null}
            </Container>

            <Input
              type='password'
              placeholder='Heslo'
              width='full'
              error={Boolean(formik.touched.password && formik.errors.password)}
              {...formik.getFieldProps('password')}
            />
            {formik.touched.password && formik.errors.password ? <Error>{formik.errors.password}</Error> : null}

            <Container top='medium' bottom='medium'>
              <Button type='submit' color='green.light' disabled={loading}>
                <Typography size='large' weight='semibold' color='green'>
                  Prihlásiť sa
                </Typography>
              </Button>
            </Container>
            <Container flex fullWidth justifyContent='center' alignItems='center'>
              <Link to='/forgotten-password'>
                <Typography size='medium' weight='semibold' color='white'>
                  Zabudol som heslo
                </Typography>
              </Link>
            </Container>
          </form>
        </Container>
      </Container>
    </BasePage>
  )
}

LoginPage.displayName = 'LoginPage'
export default LoginPage
