import * as SpotPricesApi from '../../apis/spot-prices.api'
import { IGetSpotPricesPayload, PromiseAction } from '../../shared/interfaces'

// Get spot prices
export const GET_SPOT_PRICES_TRIGGERED = 'GET_SPOT_PRICES_TRIGGERED'
export const GET_SPOT_PRICES_SUCCESS = 'GET_SPOT_PRICES_SUCCESS'
export const GET_SPOT_PRICES_FAILURE = 'GET_SPOT_PRICES_FAILURE'

export function getSpotPrices(payload: IGetSpotPricesPayload): PromiseAction {
  const promise = SpotPricesApi.getSpotPrices(payload)
  return {
    onRequest: GET_SPOT_PRICES_TRIGGERED,
    onSuccess: GET_SPOT_PRICES_SUCCESS,
    onFailure: GET_SPOT_PRICES_FAILURE,
    promise
  }
}
