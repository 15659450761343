/* eslint-disable no-shadow */
import { IAction, IActionFilter } from './distribution.interface'
import { TableSort } from './common.interface'

export interface ICreateActionRequest {
  eic?: string
}

export interface ICreateActionHistoricalDataRequest extends ICreateActionRequest {
  caz_id?: string
  dateFrom?: string
  dateTo?: string
}

export interface ICreateActionChangeOfSupplierRequest extends ICreateActionRequest {
  agreeTypeDescription?: string
}

export interface ICreateActionChangeOfParametersRequest extends ICreateActionRequest {
  process_type: string
  start_date: string
  reserved_capacity_type: string
  reserved_capacity: number
  tariff_category: string
  type_of_pds_contract: string
  rate: string
  pds_customer_name?: string
  pds_customer_street?: string
  pds_customer_street_number?: string
  pds_customer_zip_code?: string
  pds_customer_city?: string
}

export interface IGetActionsParams {
  filter?: IActionFilter
  sort?: TableSort<IAction> | undefined
  page: number
  size: number
}

export enum EIC_ACTION {
  PROCESS_EIC = 1,
  CHANGE_OF_SUPPLIER,
  MOVE_IN,
  MOVE_OUT,
  CHANGE_OF_PARAMETERS,
  DELIVERY_POINT_TRANSFER,
  HISTORICAL_DATA
}

export enum ACTION_STATUS {
  INCOMPLETE_DATA = 1,
  READY_FOR_SEND,
  SENT,
  DELIVERED,
  UNPROCESSABLE_REQUEST,
  PROCESSED,
  DECLINED,
  CANCEL_DECLINED,
  CANCELED,
  ACCEPTED,
  PAIRED,
  NOT_PAIRED
}
