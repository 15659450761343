import { IRootState } from '../../shared/interfaces'

export const bankAccountList$ = (state: IRootState) => state.financialReducer.bankAccountList
export const bankAccountDetails$ = (state: IRootState) => state.financialReducer.bankAccountDetails
export const bankAccountStatuses$ = (state: IRootState) => state.financialReducer.bankAccountStatuses

export const incomesList$ = (state: IRootState) => state.financialReducer.incomesList
export const expensesList$ = (state: IRootState) => state.financialReducer.expensesList
export const billDetails$ = (state: IRootState) => state.financialReducer.billDetails
export const billHistory$ = (state: IRootState) => state.financialReducer.billHistory

export const cribisCompanyInfo$ = (state: IRootState) => state.financialReducer.cribisCompanyInfo
