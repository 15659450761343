import React, { ReactNode } from 'react'
import { ColorsType, Typography } from '@otion-core/sandy'

import * as S from './styles'

interface Category {
  to: string
  text: string
  icon: ReactNode
  disabled?: boolean
}

interface CategoryMenuProps {
  data: Category[]
  color: ColorsType
}

const options = {
  loop: false,
  nav: false,
  dots: false,
  margin: 20,
  slideBy: 'page',
  responsive: {
    0: {
      items: 2.25
    },
    768: {
      items: 2
    },
    992: {
      items: 4
    },
    1200: {
      items: 6
    }
  }
}

const CategoryMenu = ({ data }: CategoryMenuProps) => {
  return (
    <React.Fragment>
      <S.Carousel className='owl-theme' {...options}>
        {data.map(category => (
          <S.Toolbox
            backgroundColor='white'
            key={category.to}
            to={category.to}
            icon={category.icon}
            text={
              <Typography variant='heading' size='small' weight='medium' inline>
                {category.text}
              </Typography>
            }
            size='large'
            disabled={category.disabled}
          />
        ))}
      </S.Carousel>
    </React.Fragment>
  )
}

CategoryMenu.displayName = 'CategoryMenu'
export default CategoryMenu
