import * as ActionApi from '../../apis/action.api'
import {
  EIC_ACTION,
  IAction,
  IActionFilter,
  ICreateActionChangeOfParametersRequest,
  ICreateActionChangeOfSupplierRequest,
  ICreateActionHistoricalDataRequest,
  ICreateActionRequest,
  PromiseAction,
  TableSort
} from '../../shared/interfaces'

// Statuses

export const GET_ACTION_STATUSES_TRIGGERED = 'GET_ACTION_STATUSES_TRIGGERED'
export const GET_ACTION_STATUSES_SUCCESS = 'GET_ACTION_STATUSES_SUCCESS'
export const GET_ACTION_STATUSES_FAILURE = 'GET_ACTION_STATUSES_FAILURE'

export function getActionStatuses(): PromiseAction {
  const promise = ActionApi.getActionStatuses()
  return {
    onRequest: GET_ACTION_STATUSES_TRIGGERED,
    onSuccess: GET_ACTION_STATUSES_SUCCESS,
    onFailure: GET_ACTION_STATUSES_FAILURE,
    promise
  }
}

// Get actions

export const GET_ELECTRICITY_ACTIONS_TRIGGERED = 'GET_ELECTRICITY_ACTIONS_TRIGGERED'
export const GET_ELECTRICITY_ACTIONS_SUCCESS = 'GET_ELECTRICITY_ACTIONS_SUCCESS'
export const GET_ELECTRICITY_ACTIONS_FAILURE = 'GET_ELECTRICITY_ACTIONS_FAILURE'

export const getElectricityActions = (type: EIC_ACTION) => (
  filter: IActionFilter,
  sort: TableSort<IAction> | undefined,
  page: number,
  size = 10
): PromiseAction => {
  const promise = ActionApi.getElectricityActions(type, filter, sort, page, size)
  return {
    onRequest: GET_ELECTRICITY_ACTIONS_TRIGGERED,
    onSuccess: GET_ELECTRICITY_ACTIONS_SUCCESS,
    onFailure: GET_ELECTRICITY_ACTIONS_FAILURE,
    promise,
    payload: { type, filter, page, size }
  }
}

// Create actions

export const CREATE_PROCESS_EIC_ACTION_TRIGGERED = 'CREATE_PROCESS_EIC_ACTION_TRIGGERED'
export const CREATE_PROCESS_EIC_ACTION_SUCCESS = 'CREATE_PROCESS_EIC_ACTION_SUCCESS'
export const CREATE_PROCESS_EIC_ACTION_FAILURE = 'CREATE_PROCESS_EIC_ACTION_FAILURE'

export function createProcessEicAction(payload: ICreateActionRequest): PromiseAction {
  const promise = ActionApi.createProcessEicAction(payload)
  return {
    onRequest: CREATE_PROCESS_EIC_ACTION_TRIGGERED,
    onSuccess: CREATE_PROCESS_EIC_ACTION_SUCCESS,
    onFailure: CREATE_PROCESS_EIC_ACTION_FAILURE,
    promise,
    payload: { payload }
  }
}

export const CREATE_CHANGE_OF_SUPPLIER_ACTION_TRIGGERED = 'CREATE_CHANGE_OF_SUPPLIER_ACTION_TRIGGERED'
export const CREATE_CHANGE_OF_SUPPLIER_ACTION_SUCCESS = 'CREATE_CHANGE_OF_SUPPLIER_ACTION_SUCCESS'
export const CREATE_CHANGE_OF_SUPPLIER_ACTION_FAILURE = 'CREATE_CHANGE_OF_SUPPLIER_ACTION_FAILURE'

export function createChangeOfSupplierAction(payload: ICreateActionChangeOfSupplierRequest): PromiseAction {
  const promise = ActionApi.createChangeOfSupplierAction(payload)
  return {
    onRequest: CREATE_CHANGE_OF_SUPPLIER_ACTION_TRIGGERED,
    onSuccess: CREATE_CHANGE_OF_SUPPLIER_ACTION_SUCCESS,
    onFailure: CREATE_CHANGE_OF_SUPPLIER_ACTION_FAILURE,
    promise,
    payload: { payload }
  }
}

export const CREATE_MOVE_IN_ACTION_TRIGGERED = 'CREATE_MOVE_IN_ACTION_TRIGGERED'
export const CREATE_MOVE_IN_ACTION_SUCCESS = 'CREATE_MOVE_IN_ACTION_SUCCESS'
export const CREATE_MOVE_IN_ACTION_FAILURE = 'CREATE_MOVE_IN_ACTION_FAILURE'

export function createMoveInAction(payload: ICreateActionRequest): PromiseAction {
  const promise = ActionApi.createMoveInAction(payload)
  return {
    onRequest: CREATE_MOVE_IN_ACTION_TRIGGERED,
    onSuccess: CREATE_MOVE_IN_ACTION_SUCCESS,
    onFailure: CREATE_MOVE_IN_ACTION_FAILURE,
    promise,
    payload: { payload }
  }
}

export const CREATE_MOVE_OUT_ACTION_TRIGGERED = 'CREATE_MOVE_OUT_ACTION_TRIGGERED'
export const CREATE_MOVE_OUT_ACTION_SUCCESS = 'CREATE_MOVE_OUT_ACTION_SUCCESS'
export const CREATE_MOVE_OUT_ACTION_FAILURE = 'CREATE_MOVE_OUT_ACTION_FAILURE'

export function createMoveOutAction(payload: ICreateActionRequest): PromiseAction {
  const promise = ActionApi.createMoveOutAction(payload)
  return {
    onRequest: CREATE_MOVE_OUT_ACTION_TRIGGERED,
    onSuccess: CREATE_MOVE_OUT_ACTION_SUCCESS,
    onFailure: CREATE_MOVE_OUT_ACTION_FAILURE,
    promise,
    payload: { payload }
  }
}

export const CREATE_CHANGE_OF_PARAMETERS_ACTION_TRIGGERED = 'CREATE_CHANGE_OF_PARAMETERS_ACTION_TRIGGERED'
export const CREATE_CHANGE_OF_PARAMETERS_ACTION_SUCCESS = 'CREATE_CHANGE_OF_PARAMETERS_ACTION_SUCCESS'
export const CREATE_CHANGE_OF_PARAMETERS_ACTION_FAILURE = 'CREATE_CHANGE_OF_PARAMETERS_ACTION_FAILURE'

export function createChangeOfParametersAction(payload: ICreateActionChangeOfParametersRequest): PromiseAction {
  const promise = ActionApi.createChangeOfParametersAction(payload)
  return {
    onRequest: CREATE_CHANGE_OF_PARAMETERS_ACTION_TRIGGERED,
    onSuccess: CREATE_CHANGE_OF_PARAMETERS_ACTION_SUCCESS,
    onFailure: CREATE_CHANGE_OF_PARAMETERS_ACTION_FAILURE,
    promise,
    payload: { payload }
  }
}

export const CREATE_DP_TRANSFER_ACTION_TRIGGERED = 'CREATE_DP_TRANSFER_ACTION_TRIGGERED'
export const CREATE_DP_TRANSFER_ACTION_SUCCESS = 'CREATE_DP_TRANSFER_ACTION_SUCCESS'
export const CREATE_DP_TRANSFER_ACTION_FAILURE = 'CREATE_DP_TRANSFER_ACTION_FAILURE'

export function createDpTransferAction(payload: ICreateActionRequest): PromiseAction {
  const promise = ActionApi.createDpTransferAction(payload)
  return {
    onRequest: CREATE_DP_TRANSFER_ACTION_TRIGGERED,
    onSuccess: CREATE_DP_TRANSFER_ACTION_SUCCESS,
    onFailure: CREATE_DP_TRANSFER_ACTION_FAILURE,
    promise,
    payload: { payload }
  }
}

export const CREATE_HISTORICAL_DATA_ACTION_TRIGGERED = 'CREATE_HISTORICAL_DATA_ACTION_TRIGGERED'
export const CREATE_HISTORICAL_DATA_ACTION_SUCCESS = 'CREATE_HISTORICAL_DATA_ACTION_SUCCESS'
export const CREATE_HISTORICAL_DATA_ACTION_FAILURE = 'CREATE_HISTORICAL_DATA_ACTION_FAILURE'

export function createHistoricalDataAction(payload: ICreateActionHistoricalDataRequest): PromiseAction {
  const promise = ActionApi.createHistoricalDataAction(payload)
  return {
    onRequest: CREATE_HISTORICAL_DATA_ACTION_TRIGGERED,
    onSuccess: CREATE_HISTORICAL_DATA_ACTION_SUCCESS,
    onFailure: CREATE_HISTORICAL_DATA_ACTION_FAILURE,
    promise,
    payload: { payload }
  }
}
