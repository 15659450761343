import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { CustomerStatusEnum } from 'src/shared/interfaces'

interface CustomerStatusProps {
  value: CustomerStatusEnum
}

const CustomerStatus = (props: CustomerStatusProps) => {
  const { t } = useTranslation()

  if (!props.value) return null

  let color

  switch (props?.value) {
    case CustomerStatusEnum.NEW:
      color = '#f1e0a7'
      break
    case CustomerStatusEnum.ACTIVE:
      color = '#8abd25'
      break
    case CustomerStatusEnum.INACTIVE:
      color = '#ff3b30'
      break
    default:
      color = '#ddd'
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`CUSTOMER_STATUS.${props.value}` as never)}</span>
    </Container>
  )
}

CustomerStatus.displayName = 'CustomerStatus'
export default CustomerStatus
