import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { messageStatuses$ } from 'src/redux/selectors'
import { ColorDot } from 'src/components/commonStyled'

interface MessageStatusProps {
  value?: number | string
}

const MessageStatus = (props: MessageStatusProps) => {
  const { t } = useTranslation()
  const messageStatuses = useSelector(messageStatuses$)

  if (!props.value) return null

  const status = messageStatuses.find(messageStatus => messageStatus.id === Number(props.value))
  let color

  switch (Number(status?.id)) {
    case 1:
      color = '#e6e6e6'
      break
    case 2:
      color = '#f3d163'
      break
    case 3:
      color = '#ff3b30'
      break
    case 4:
      color = '#8abd25'
      break
    case 5:
      color = '#ff3b30'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`MESSAGE_STATUS.${status?.name}` as never)}</span>
    </Container>
  )
}

MessageStatus.displayName = 'MessageStatus'
export default MessageStatus
