import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@otion-core/sandy'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import * as S from './styles'

interface AddConnectionModalProps {
  onClose: () => void
  onAddConnections: (data: number[]) => void
}

const AddConnectionModal = (props: AddConnectionModalProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { onClose, onAddConnections } = props
  const [keyword, setKeyword] = useState('')
  const [selectedConnections, setSelectedConnections] = useState<number[]>([])

  const connections: any = []

  useEffect(() => {
    // console.info('Get connections?')
  }, [dispatch, keyword])

  const onClickAddConnection = () => {
    onAddConnections(selectedConnections)
    onClose()
  }

  const handleClose = () => {
    setSelectedConnections([])
    setKeyword('')
    onClose()
  }

  const handleClickConnectionItem = (id: number) => {
    const index = selectedConnections.indexOf(id)
    const newConnections = [...selectedConnections]
    if (index > -1) {
      newConnections.splice(index, 1) // eslint-disable-line fp/no-mutating-methods
    } else {
      newConnections.push(id) // eslint-disable-line fp/no-mutating-methods
    }
    setSelectedConnections(newConnections)
  }

  return (
    <S.AddConnectionModal
      title={
        <Typography variant='heading' weight='semibold' size='small' style={{ marginLeft: '10px' }}>
          {t('ENTER_CONNECTION_NAME')}
        </Typography>
      }
      open
      size='small'
      closeHandler={handleClose}
    >
      <S.Input width='full' onChange={e => setKeyword(e.target.value)} />
      <S.List>
        {connections.map((connection: any) => (
          <S.ListItem
            key={connection.id}
            active={selectedConnections.indexOf(connection.id) > -1}
            onClick={() => handleClickConnectionItem(connection.id)}
          >
            <Typography size='medium'>{connection.name}</Typography>
          </S.ListItem>
        ))}
      </S.List>
      <Button
        size='xsmall'
        fullWidth
        style={{ fontSize: '13px', marginTop: '20px' }}
        color='blue'
        disabled={selectedConnections.length === 0}
        onClick={onClickAddConnection}
      >
        {t('ADD_CONNECTION')}
      </Button>
    </S.AddConnectionModal>
  )
}

AddConnectionModal.displayName = 'AddConnectionModal'
export default AddConnectionModal
