import styled from 'styled-components'
import { Typography } from '@otion-core/sandy'

const OptionName = styled(Typography)`
  min-width: 45%;
  max-width: 45%;
  margin-bottom: 20px;
  padding-right: 20px;

  &.mb-0 {
    margin-bottom: 0;
  }

  &.mt-5 {
    margin-top: 5px;
  }
`

export { OptionName }
