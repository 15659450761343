import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'

interface ProformaInvoiceStatusProps {
  value?: number | string
}

const ProformaInvoiceStatus = (props: ProformaInvoiceStatusProps) => {
  const { t } = useTranslation()

  if (!props.value) return null

  const status = [
    { id: 1, name: 'WAITING_FOR_CONFIRMATION' },
    { id: 2, name: 'WAITING_FOR_ISSUE' },
    { id: 3, name: 'ISSUED' },
    { id: 4, name: 'CANCELED' }
  ].find(proformaInvoiceStatus => proformaInvoiceStatus.id === Number(props.value))
  let color

  switch (Number(status?.id)) {
    case 1:
      color = '#f1e0a7'
      break
    case 2:
      color = '#ffa300'
      break
    case 3:
      color = '#8abd25'
      break
    case 4:
      color = '#ff3b30'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`PROFORMA_INVOICE_STATUS.${status?.name}` as never)}</span>
    </Container>
  )
}

ProformaInvoiceStatus.displayName = 'ProformaInvoiceStatus'
export default ProformaInvoiceStatus
