import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { ThemeContext } from 'styled-components'
import { Typography, Container, Button, BasePage, Link, Paper, getBPHelpers } from '@otion-core/sandy'

import { forgotPassword } from 'src/redux/actions'
import { IForgotPasswordPayload } from 'src/shared/interfaces'
import { Input } from 'src/components/commonStyled'
import BackgroundImage from 'src/shared/images/password-background.png'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required.')
})

const ForgottenPasswordPage = () => {
  const dispatch = useDispatch()
  const themeContext = useContext(ThemeContext)
  const BPHelpers = getBPHelpers(themeContext.breakpoints)
  const isSmall = BPHelpers.useBreakpoint('small')
  const [isMailSent, setMailSent] = useState(false)

  const submitHandler = async (values: IForgotPasswordPayload) => {
    const response = await dispatch(forgotPassword(values))
    if (response?.data?.success) setMailSent(true)
    return response
  }

  return (
    <BasePage
      title='Forgotten Password'
      isLanding
      centered
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        maxWidth: '100vw'
      }}
    >
      <Formik
        initialValues={{ email: '' } as IForgotPasswordPayload}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {formikProps => (
          <Form>
            <Container flex fullWidth alignItems='center' justifyContent='center'>
              <Container fullWidth direction='column' justifyContent='center' style={{ maxWidth: 500 }}>
                <Typography
                  align='center'
                  font='secondary'
                  style={{ fontSize: isSmall ? 32 : 42 }}
                  variant='heading'
                  color='white'
                >
                  Obnova Hesla
                </Typography>

                {isMailSent ? (
                  <Container top='medium' bottom='medium'>
                    <Paper style={{ background: 'rgba(50,50,50,60%)' }}>
                      <Container padded='large'>
                        <Typography color='green.light' weight='semibold' align='center'>
                          E-mail bol odoslaný
                        </Typography>
                      </Container>
                    </Paper>
                  </Container>
                ) : (
                  <React.Fragment>
                    <Container top='medium' bottom='medium'>
                      <Field name='email' as={Input} placeholder='E-mail' width='full' />
                      <ErrorMessage name='email'>
                        {msg => (
                          <Typography color='red' size='medium' weight='semibold'>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Container>

                    <Container bottom='medium'>
                      <Button
                        type='submit'
                        color='blue.light'
                        disabled={formikProps.isSubmitting}
                        loading={formikProps.isSubmitting}
                      >
                        <Typography size='large' weight='semibold' color='blue'>
                          Obnoviť heslo
                        </Typography>
                      </Button>
                    </Container>
                  </React.Fragment>
                )}

                <Container flex fullWidth justifyContent='center' alignItems='center'>
                  <Link to='/login'>
                    <Typography size='medium' weight='semibold' color='white'>
                      Späť na prihlásenie
                    </Typography>
                  </Link>
                </Container>
              </Container>
            </Container>
          </Form>
        )}
      </Formik>
    </BasePage>
  )
}

ForgottenPasswordPage.displayName = 'ForgottenPasswordPage'
export default ForgottenPasswordPage
