import React, { ReactNode, forwardRef, isValidElement } from 'react'
import { BaseComponent } from '@otion-core/sandy'

import * as S from './styles'

interface ExpansionPanelProps extends BaseComponent {
  /** Panel content */
  children: ReactNode
  /** Disabled */
  disabled?: boolean
  /** Expanded */
  expanded?: boolean
  /** Callback invoked when component is clicked */
  onChange?: (event: MouseEvent, expanded: boolean) => void
}
export const ExpansionPanel = forwardRef<HTMLDivElement, ExpansionPanelProps>(function ExpansionPanel(
  { children, disabled, expanded, onChange, ...rest },
  ref
) {
  const items = Array.isArray(children) ? children : [children]

  return (
    <S.Content ref={ref} {...rest}>
      {items.map((child, index) => {
        if (isValidElement(child)) {
          const childProps = {
            key: index,
            onChange,
            disabled,
            expanded
          }
          return React.cloneElement(child, childProps)
        }
        return false
      })}
    </S.Content>
  )
})

ExpansionPanel.defaultProps = {
  disabled: false,
  expanded: false
}

ExpansionPanel.displayName = 'ExpansionPanel'
export default ExpansionPanel
