import React, { ReactNode, forwardRef } from 'react'
import cx from 'classnames'
import { BaseComponent, Icon } from '@otion-core/sandy'

import * as S from './styles'

interface ExpansionPanelSummaryProps extends BaseComponent {
  /** Summary content */
  children: ReactNode
  /** Disabled */
  disabled?: boolean
  /** Expanded */
  expanded?: boolean
  /** Callback invoked when component is clicked */
  onChange?: (event: React.MouseEvent<HTMLDivElement>, expanded: boolean) => void
}
export const ExpansionPanelSummary = forwardRef<HTMLDivElement, ExpansionPanelSummaryProps>(
  function ExpansionPanelSummary({ children, disabled, expanded, className, onChange, ...rest }, ref) {
    const expandedClassName = expanded ? 'expanded' : 'collapsed'
    const disabledClassName = disabled ? 'disabled' : ''

    const rootClass = cx(className, expandedClassName, disabledClassName)

    const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
      if (onChange) {
        onChange(event, !expanded)
      }
    }
    return (
      <S.ContentWrapper className={rootClass} onClick={e => handleClick(e)} ref={ref} {...rest}>
        {children}
        <Icon name='dropdownArrow' className='expand-icon' />
      </S.ContentWrapper>
    )
  }
)

ExpansionPanelSummary.defaultProps = {
  disabled: false,
  expanded: false
}

ExpansionPanelSummary.displayName = 'ExpansionPanelSummary'
export default ExpansionPanelSummary
