import {
  IForgotPasswordPayload,
  ILoginPayload,
  IResetPasswordPayload,
  ISignInAsPayload,
  PromiseAction
} from '../../shared/interfaces'
import * as AuthApi from '../../apis/auth.api'

export const LOGIN_TRIGGERED = 'LOGIN_TRIGGERED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export function login(payload: ILoginPayload): PromiseAction {
  const promise = AuthApi.login(payload)
  return {
    onRequest: LOGIN_TRIGGERED,
    onSuccess: LOGIN_SUCCESS,
    onFailure: LOGIN_FAILURE,
    promise
  }
}

export const ME_TRIGGERED = 'ME_TRIGGERED'
export const ME_SUCCESS = 'ME_SUCCESS'
export const ME_FAILURE = 'ME_FAILURE'

export function getMe(): PromiseAction {
  const promise = AuthApi.getMe()
  return {
    onRequest: ME_TRIGGERED,
    onSuccess: ME_SUCCESS,
    onFailure: ME_FAILURE,
    promise
  }
}

export const SIGN_IN_AS_TRIGGERED = 'SIGN_IN_AS_TRIGGERED'
export const SIGN_IN_AS_SUCCESS = 'SIGN_IN_AS_SUCCESS'
export const SIGN_IN_AS_FAILURE = 'SIGN_IN_AS_FAILURE'

export function signInAs(payload: ISignInAsPayload): PromiseAction {
  const promise = AuthApi.signInAs(payload)
  return {
    onRequest: SIGN_IN_AS_TRIGGERED,
    onSuccess: SIGN_IN_AS_SUCCESS,
    onFailure: SIGN_IN_AS_FAILURE,
    promise
  }
}

export const FORGOT_PASSWORD_TRIGGERED = 'FORGOT_PASSWORD_TRIGGERED'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

export function forgotPassword(payload: IForgotPasswordPayload): PromiseAction {
  const promise = AuthApi.forgotPassword(payload)
  return {
    onRequest: FORGOT_PASSWORD_TRIGGERED,
    onSuccess: FORGOT_PASSWORD_SUCCESS,
    onFailure: FORGOT_PASSWORD_FAILURE,
    promise
  }
}

export const CHECK_RESET_TOKEN_TRIGGERED = 'CHECK_RESET_TOKEN_TRIGGERED'
export const CHECK_RESET_TOKEN_SUCCESS = 'CHECK_RESET_TOKEN_SUCCESS'
export const CHECK_RESET_TOKEN_FAILURE = 'CHECK_RESET_TOKEN_FAILURE'

export function checkResetToken(token: string): PromiseAction {
  const promise = AuthApi.checkResetToken(token)
  return {
    onRequest: CHECK_RESET_TOKEN_TRIGGERED,
    onSuccess: CHECK_RESET_TOKEN_SUCCESS,
    onFailure: CHECK_RESET_TOKEN_FAILURE,
    promise
  }
}

export const RESET_PASSWORD_TRIGGERED = 'RESET_PASSWORD_TRIGGERED'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

export function resetPassword(payload: IResetPasswordPayload): PromiseAction {
  const promise = AuthApi.resetPassword(payload)
  return {
    onRequest: RESET_PASSWORD_TRIGGERED,
    onSuccess: RESET_PASSWORD_SUCCESS,
    onFailure: RESET_PASSWORD_FAILURE,
    promise
  }
}
