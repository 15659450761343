import { AnyAction } from 'redux'

import { IPaginationResponse, IFinancialState, IBill, IBankAccount, IBankAccountStatus } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IFinancialState = {
  bankAccountList: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  bankAccountDetails: undefined,
  bankAccountStatuses: [],
  incomesList: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  expensesList: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  billDetails: undefined,
  billHistory: [],
  cribisCompanyInfo: undefined
}

const financialReducer = (state = initialState, action: AnyAction): IFinancialState => {
  switch (action.type) {
    case actionTypes.GET_BANK_ACCOUNT_SUCCESS: {
      const response: IPaginationResponse<IBankAccount> = action.response?.data
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length
        ? state.bankAccountList.totalCount
        : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        bankAccountList: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_BANK_ACCOUNT_STATUSES_SUCCESS: {
      const statuses: IBankAccountStatus[] = action.response?.data
      // eslint-disable-next-line fp/no-mutating-methods
      return { ...state, bankAccountStatuses: statuses.sort((statusA, statusB) => statusA.id - statusB.id) }
    }

    case actionTypes.GET_BANK_ACCOUNT_DETAIL_SUCCESS: {
      return { ...state, bankAccountDetails: action.response?.data }
    }

    case actionTypes.GET_INCOMES_SUCCESS: {
      const response: IPaginationResponse<IBill> = action.response?.data
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length
        ? state.incomesList.totalCount
        : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        incomesList: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_EXPENSES_SUCCESS: {
      const response: IPaginationResponse<IBill> = action.response?.data
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length
        ? state.expensesList.totalCount
        : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        expensesList: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_BILL_DETAIL_SUCCESS:
    case actionTypes.RECALCULATE_BILL_BALANCE_SUCCESS: {
      return { ...state, billDetails: action.response?.data }
    }

    case actionTypes.GET_BILL_HISTORY_BY_BILL_SUCCESS:
    case actionTypes.GET_BILL_HISTORY_BY_BANK_ACCOUNT_SUCCESS: {
      return { ...state, billHistory: action.response?.data }
    }

    case actionTypes.GET_COMPANY_FROM_CRIBIS_SUCCESS: {
      return { ...state, cribisCompanyInfo: action.response?.data }
    }

    default:
      return state
  }
}

export default financialReducer
