import styled from 'styled-components'
import { Input as SInput, Modal as SModal, Toolbox as SToolbox } from '@otion-core/sandy/build/components'
import { Typography } from '@otion-core/sandy'

const AddTaskModal = styled(SModal)`
  > div > div {
    max-width: 940px !important;
  }
`

const Input = styled(SInput)`
  input {
    line-height: 38px !important;
    padding: 0 20px !important;
  }
`

const OptionName = styled(Typography)`
  width: 40%;
  margin-bottom: 20px;

  &.mb-0 {
    margin-bottom: 0;
  }

  &.mt-5 {
    margin-top: 5px;
  }
`

const SearchInput = styled(SInput)`
  input {
    line-height: 38px !important;
    padding: 0 6px !important;
  }
`

const Heading = styled(Typography)`
  font-size: 17px !important;
  margin-bottom: 10px;
`

const Toolbox = styled(SToolbox)`
  width: 180px !important;
  height: 110px !important;

  > div {
    border-radius: 20px !important;
  }
`

export { AddTaskModal, Input, OptionName, SearchInput, Heading, Toolbox }
