import { EIC_ACTION } from '../interfaces'

export const actionPath = {
  [EIC_ACTION.CHANGE_OF_SUPPLIER]: 'change-of-supplier',
  [EIC_ACTION.PROCESS_EIC]: 'process-eic',
  [EIC_ACTION.MOVE_IN]: 'move-in',
  [EIC_ACTION.MOVE_OUT]: 'move-out',
  [EIC_ACTION.CHANGE_OF_PARAMETERS]: 'change-of-parameters',
  [EIC_ACTION.DELIVERY_POINT_TRANSFER]: 'delivery-point-transfer'
}
