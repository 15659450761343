import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { Container, getBPHelpers } from '@otion-core/sandy'

import { user$ } from 'src/redux/selectors'
import { UserRole } from 'src/shared/interfaces'
import FooterImg from 'src/shared/images/client-footer.jpg'
import LogoSvg from 'src/shared/images/logo-small.svg'

import * as S from './styles'

const ClientFooter = () => {
  const themeContext = useContext(ThemeContext)
  const BPHelpers = getBPHelpers(themeContext.breakpoints)
  const isSmall = BPHelpers.useBreakpoint('small')
  const user = useSelector(user$)

  const isClient = user?.role === UserRole.CLIENT
  if (!isClient) return null

  return (
    <Container top='xlarge' fullWidth>
      <S.FooterPaper style={{ backgroundImage: `url(${FooterImg})` }}>
        <Container padded='large' flex direction='column' justifyContent='space-between'>
          <Container>
            <S.LabelText font='secondary' size='medium'>
              KONTAKTUJTE NÁS
            </S.LabelText>
            <S.BigLink external to='mailto:info@encare.sk'>
              info@encare.sk
            </S.BigLink>
            <S.BigLink external to='tel:+421902560560'>
              +421 902 560 560
            </S.BigLink>
          </Container>

          <Container top='large'>
            <S.LabelText font='secondary' size='medium'>
              BANKOVÉ ÚDAJE
            </S.LabelText>
            <S.BankInfo font='secondary'>
              <span>
                <span>Sídlo:</span> Rusovská cesta 20, 851 01 Bratislava
              </span>
              <span>
                <span>IČO:</span> 52302555
              </span>
              <span>
                <span>Banka:</span> SK15 1100 0000 0029 4506 9129
              </span>
            </S.BankInfo>
          </Container>
        </Container>

        {isSmall ? null : (
          <Container padded='large' flex alignItems='flex-end' justifyContent='flex-end'>
            <img src={LogoSvg} alt='encare' />
          </Container>
        )}
      </S.FooterPaper>
    </Container>
  )
}

ClientFooter.displayName = 'ClientFooter'
export default ClientFooter
