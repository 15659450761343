import moment from 'moment'
import {
  IPaginationResponse,
  IInvoice,
  IInvoicesFilter,
  TableSort,
  IPostGenerateInvoices,
  IPairedBankAccountResponse
} from 'src/shared/interfaces'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'
import { DATE_FORMAT_DB } from '../shared/constants'

export const getAllInvoices = (
  filter: IInvoicesFilter,
  sort: TableSort<IInvoice> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_period_start: [
        filter.delivery_period_start_from
          ? moment(filter.delivery_period_start_from).format(DATE_FORMAT_DB)
          : undefined,
        filter.delivery_period_start_to ? moment(filter.delivery_period_start_to).format(DATE_FORMAT_DB) : undefined
      ],
      type_of_invoice: filter.type_of_invoice,
      status: filter.status
    },
    searchBy: ['customer', 'variable_symbol', 'company_number'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IInvoice>>('/invoices', payload)
}

export const generateProformaInvoices = (payload: IPostGenerateInvoices) => {
  return Http.post<{ count: number }>('/invoices/generate-proforma-invoices', payload)
}

export const generatePaymentInvoices = (payload: IPostGenerateInvoices) => {
  return Http.post<{ count: number }>('/invoices/generate-payment-invoices', payload)
}

export const createInvoiceForVfa = (id: number) => {
  return Http.post<IInvoice>(`/invoices/create-invoices-for-vfa/${id}`)
}

export const getInvoiceDetails = (id: number) => {
  return Http.get<IInvoice>(`/invoices/${id}`)
}

export const updateInvoiceDetails = (id: number, payload: Partial<IInvoice>) => {
  return Http.patch<IInvoice>(`/invoices/${id}`, payload)
}

export const sendInvoice = (id: number) => {
  return Http.post<unknown>(`/invoices/${id}/send-invoice`)
}

export const forceIssueInvoice = (id: number) => {
  return Http.post<unknown>(`/invoices/${id}/force-issue`)
}

export const cancelInvoice = (id: number) => {
  return Http.post<IInvoice>(`/invoices/${id}/cancel-invoice`)
}

export const regenerateInvoice = (id: number) => {
  return Http.post<IInvoice>(`/invoices/${id}/reload-customer-info`)
}

export const getPdfInvoicePreview = (id: number) => {
  return Http.get<unknown>(`/pdf/invoice/${id}?preview=true`, { responseType: 'blob' })
}

export const getPdfInvoice = (id: number) => {
  return Http.getFile<unknown>(`/pdf/invoice/${id}`)
}

export const tryToPairBankAccounts = () => {
  return Http.get<IPairedBankAccountResponse>(`/bills/pair/bank-accounts`)
}

export const exportInvoices = (filter: IInvoicesFilter) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_period_start: [
        filter.delivery_period_start_from
          ? moment(filter.delivery_period_start_from).format(DATE_FORMAT_DB)
          : undefined,
        filter.delivery_period_start_to ? moment(filter.delivery_period_start_to).format(DATE_FORMAT_DB) : undefined
      ],
      type_of_invoice: filter.type_of_invoice,
      status: filter.status
    },
    searchBy: ['customer', 'variable_symbol', 'company_number'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(`/invoices/xls/export?filter=${JSON.stringify(payload)}`)
}
