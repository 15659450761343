import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { bankAccountStatuses$ } from 'src/redux/selectors'

interface BankAccountStatusProps {
  value?: number | string
}

const BankAccountStatus = (props: BankAccountStatusProps) => {
  const { t } = useTranslation()
  const bankAccountStatuses = useSelector(bankAccountStatuses$)

  if (!props.value) return null

  const status = bankAccountStatuses.find(bankAccountStatus => bankAccountStatus.id === Number(props.value))
  let color

  switch (Number(status?.id)) {
    case 1:
      color = '#ff3b30'
      break
    case 2:
      color = '#8abd25'
      break
    case 3:
      color = '#424242'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`BANK_ACCOUNT_STATUS.${status?.name}` as never)}</span>
    </Container>
  )
}

BankAccountStatus.displayName = 'BankAccountStatus'
export default BankAccountStatus
