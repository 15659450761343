import * as PriceListApi from '../../apis/priceList.api'
import { IRateItem, IRatesFilter, PromiseAction, TableSort } from '../../shared/interfaces'

// Get rates

export const GET_RATES_TRIGGERED = 'GET_RATES_TRIGGERED'
export const GET_RATES_SUCCESS = 'GET_RATES_SUCCESS'
export const GET_RATES_FAILURE = 'GET_RATES_FAILURE'

export function getRates(
  filter: IRatesFilter,
  sort: TableSort<IRateItem> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = PriceListApi.getRates(filter, sort, page, size)
  return {
    onRequest: GET_RATES_TRIGGERED,
    onSuccess: GET_RATES_SUCCESS,
    onFailure: GET_RATES_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get price details

export const GET_PRICE_DETAIL_TRIGGERED = 'GET_PRICE_DETAIL_TRIGGERED'
export const GET_PRICE_DETAIL_SUCCESS = 'GET_PRICE_DETAIL_SUCCESS'
export const GET_PRICE_DETAIL_FAILURE = 'GET_PRICE_DETAIL_FAILURE'

export function getPriceDetail(priceId: number): PromiseAction {
  const promise = PriceListApi.getPriceDetail(priceId)
  return {
    onRequest: GET_PRICE_DETAIL_TRIGGERED,
    onSuccess: GET_PRICE_DETAIL_SUCCESS,
    onFailure: GET_PRICE_DETAIL_FAILURE,
    promise,
    payload: { priceId }
  }
}

// Update price rate

export const UPDATE_PRICE_DETAIL_TRIGGERED = 'UPDATE_PRICE_DETAIL_TRIGGERED'
export const UPDATE_PRICE_DETAIL_SUCCESS = 'UPDATE_PRICE_DETAIL_SUCCESS'
export const UPDATE_PRICE_DETAIL_FAILURE = 'UPDATE_PRICE_DETAIL_FAILURE'

export function updatePriceDetail(priceId: number, values: IRateItem): PromiseAction {
  const promise = PriceListApi.updatePriceDetail(priceId, values)
  return {
    onRequest: UPDATE_PRICE_DETAIL_TRIGGERED,
    onSuccess: UPDATE_PRICE_DETAIL_SUCCESS,
    onFailure: UPDATE_PRICE_DETAIL_FAILURE,
    promise,
    payload: { priceId, values }
  }
}
