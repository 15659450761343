import { commonEn, commonSk } from './common'
import { electricityEn, electricitySk } from './sections/electricity'
import { financialsEn, financialsSk } from './sections/financials'
import { clientEn, clientSk } from './sections/client'
import { tradeEn, tradeSk } from './sections/trade'
import { traderEn, traderSk } from './sections/trader'

const en = {
  common: commonEn,
  electricity: electricityEn,
  financials: financialsEn,
  client: clientEn,
  trade: tradeEn,
  trader: traderEn
}

// typeguarding SK localization, so it corresponds the english one
const sk: typeof en = {
  common: commonSk,
  electricity: electricitySk,
  financials: financialsSk,
  client: clientSk,
  trade: tradeSk,
  trader: traderSk
}

export type translationNamespace = keyof typeof en

export default { en, sk } as const
