import styled from 'styled-components'
import { Toolbox as SToolbox } from '@otion-core/sandy/build/components'

const Container = styled(SToolbox)`
  width: 180px !important;
  height: 110px !important;
  cursor: pointer;
  user-select: none;
  font-size: 11px;

  > div {
    border-radius: 20px !important;
  }

  > svg {
    fill: #242424 !important;
  }

  .file-icon > svg {
    width: 40px;
    height: 40px;
  }

  .item-text {
    letter-spacing: -0.015rem;
    line-height: 1.33;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    font-family: inherit !important;
  }

  &.selected > div {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primary} inset;
  }

  .button-remove {
    box-shadow: none;
    opacity: 0;
    position: absolute;
    top: -4px;
    transition: all 200ms;
    right: -12px;
    transform: scale(0.8);

    svg {
      fill: ${props => props.theme.colors.red.default} !important;
    }

    &:hover {
      background: ${props => props.theme.colors.red.default};

      svg {
        fill: ${props => props.theme.colors.white.default} !important;
      }
    }
  }

  &:hover {
    .button-remove {
      opacity: 1;
    }
  }
`

export { Container }
