/* eslint-disable no-shadow */
import { IContract } from './contracts.interface'

export enum EicStatusEnum {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  WAITING_FOR_DELIVERY = 'WAITING_FOR_DELIVERY',
  ONGOING_CONNECTION = 'ONGOING_CONNECTION',
  UNSUCCESSFUL_CONNECTION = 'UNSUCCESSFUL_CONNECTION',
  ACTIVE_DELIVERY = 'ACTIVE_DELIVERY',
  DISCONNECTED_DISTRIBUTION = 'DISCONNECTED_DISTRIBUTION',
  ONGOING_TERMINATION = 'ONGOING_TERMINATION',
  TERMINATED_DELIVERY = 'TERMINATED_DELIVERY',
  DRAFT = 'DRAFT'
}

export interface IEic {
  address_city: string
  address_number: string
  address_street: string
  address_zip_code: string
  contract_number: number
  customer: string
  delivery_end: string
  delivery_start: string
  type_of_measurement?: string
  eic: string
  id: number
  rzm: number
  status: EicStatusEnum
}

export interface ICreateEicDraftPayload {
  eic: string
  address_city: string
  address_number: string
  address_street: string
  address_zip_code: string
  rate: string
  delivery_start: Date
  delivery_end: Date
  voltage_level: string
  number_of_phases: string
  reserved_capacity: string
  unit_of_reserved_capacity?: string
  reserved_capacity_type?: string
  rzm: string
}

export interface IEicDetails {
  supplementary_invoice_category?: string
  distribution_rate_category?: string
  address_city?: string
  address_number?: string
  address_street?: string
  address_zip_code?: string
  billing_cycle?: number
  ims_meter_functionality_type?: string
  caz_id?: string
  connections: EicConnection[]
  constant: number
  consumption: number
  consumption_period?: string
  contracts: IContract[]
  created_at?: string
  created_by: string
  date_of_reserved_capacity?: string
  deduction_date?: string
  supply_security_level?: string
  delivery_end?: string
  delivery_start?: string
  drop_off_point_type?: string
  difference: number
  distribution_connection_status?: string
  distribution_system: string
  dpi_status?: string
  eic: string
  eic_details_id: number
  electricity_meter_id?: string
  end_of_distribution?: string
  estimated_annual_consumption_kwh: number
  id: number
  loss_coefficient: number
  low_tariff_operator_code?: string
  max_reserved_capacity?: string
  meter_access_type?: string
  measurement_side?: string
  notice_period_for_termination_months: number
  number_of_phases: number
  pds_customer_city?: string
  pds_customer_company_id?: string
  pds_customer_email?: string
  pds_customer_name?: string
  pds_customer_street?: string
  pds_customer_street_no?: string
  pds_customer_telephone?: string
  distribution_business_partner_type?: string
  pds_customer_zip?: string
  pds_eic_city?: string
  pds_eic_street?: string
  pds_eic_street_no?: string
  pds_eic_zip?: string
  performance_surcharge?: string
  periodicity_of_invoicing: number
  power_factor?: string
  energy_consumption_parameters?: string
  rate: string
  reason_for_deduction?: string
  register_name?: string
  register_number: number
  reserved_capacity?: string
  reserved_capacity_type?: string
  scope_of_the_register?: string
  small_business?: string
  start_of_distribution?: string
  status: EicStatusEnum
  tariff_category?: string
  typical_consumption_profile?: string
  transformer_utilization: number
  type_of_deduction?: string
  delivery_point_type?: string
  type_of_measurement?: string
  type_of_pds_contract?: string
  unit?: string
  unit_of_reserved_capacity: string
  updated_at: string
  updated_by: string
  value_after_deduction: number
  value_before_deduction: number
  voltage_level?: string
  vulnerable_customer?: string
}

export interface CreateEicPayload {
  eic: string
  address_street: string
  address_number: string
  address_zip_code: string
  address_city: string
  rate?: string
  voltage_level: string
  number_of_phases: string
  reserved_capacity: string
  unit_of_reserved_capacity?: string
  reserved_capacity_type?: string
  delivery_start: Date
}

export interface EicConnection {
  action_id?: number
  case_id: string
  caz_id: string
  customer?: string
  eic: string
  error_status?: number
  id: number
  pds: string
  rdz: string
  reason_of_decline?: number
  service?: string
  status?: number
  subprocess?: number
}

export interface IEicSimpleListItem {
  id: number
  eic: string
  delivery_end: string
  status: EicStatusEnum
}

export interface IEicAction {
  id: number
  name: string
}

export interface IEicFilter {
  search?: string
  status?: EicStatusEnum
  rate?: string
  supply_start_date_from?: string
  supply_start_date_to?: string
  supply_end_date_from?: string
  supply_end_date_to?: string
  rzm_from?: number
  rzm_to?: number
  status_code?: number
  new_eics?: boolean
  terminated_eics?: boolean
}

export interface ISetTerminationDateEicPayload {
  delivery_end: string | Date
}

export enum EicPdsContractType {
  CUSTOMER = 'E01',
  SUPPLIER = 'E02',
  MANUFACTURER = 'E03'
}

export enum EicReservedCapacityType {
  ADAPT_KW = 'E00',
  MONTHLY_KW = 'E01',
  QUARTERLY_KW = 'E03',
  ANNUAL_KW = 'E12',
  RK_VALUE_A = 'E91'
}

export enum EicTariffCategory {
  ONE_T = '1T',
  TWO_T = '2T',
  THREE_T = '3T',
  ONE_S = '1S',
  TWO_A = '2A',
  TWO_P = '2P',
  TWO_C = '2C'
}

export enum EicTypicalConsumptionProfile {
  TDO = '00',
  TDO1_MO = '01',
  TDO2_MO = '02',
  TDO3_MO = '03',
  TDO4_DOM = '04',
  TDO5_DOM = '05',
  TDO6_DOM = '06',
  TDO8_VO = '08'
}

export enum EicImsMeterFunctionalityType {
  BASIC_FUNCTIONALITY = 'E81',
  ADVANCED_FUNCTIONALITY = 'E82',
  SPECIAL_FUNCTIONALITY = 'E83',
  WITHOUT_IMS = 'E89'
}

export enum EicSmallBusinessStatus {
  NO = '0',
  YES = '1'
}

export enum EicDistributionRateCategory {
  HOUSEHOLD = 'D',
  NON_HOUSEHOLD = 'MD'
}

export enum EicVulnerableCustomerStatus {
  NO = '0',
  YES = '1'
}

export enum EicDpiStatus {
  OUT_OF_DPI = '0',
  IN_DPI = '1'
}

export enum EicEnergyConsumptionParameters {
  MEASURED_POWER_W = 'E01',
  UNMETERED_CONSUMPTION_COUNT = 'E02'
}

export enum EicDistributionBusinessPartnerType {
  INDIVIDUAL = '1',
  ENTREPRENEUR_INDIVIDUAL = '2',
  ENTREPRENEUR_LEGAL_PERSON = '3',
  ENTREPRENEUR_SPECIAL_PERSON = '4'
}

export enum EicDeliveryPointType {
  CONSUMPTION = 'E17',
  PRODUCTION = 'E18',
  CONSUMPTION_AND_PRODUCTION = 'E19'
}

export enum EicMeterAccessType {
  PERMANENTLY_ACCESSIBLE_FROM_PUBLIC_ROAD = '01',
  ACCESS_WITH_OWNER_CONSENT = '02',
  MEASUREMENT_PERMANENTLY_ACCESSIBLE_FROM_PUBLIC_ROAD = '03',
  COMBINED_METER_BOX_AT_ENTRANCE = '04'
}

export enum EicSupplySecurityLevel {
  FIRST_LEVEL_TWO_SOURCES = '01',
  SECOND_LEVEL_BACKUP = '02',
  THIRD_LEVEL_STANDARD = '03'
}

export enum EicDistributionConnectionStatus {
  CONNECTED = 'E22',
  DISCONNECTED = 'E23'
}

export enum EicProcessType {
  CHANGE_RESERVED_CAPACITY_KW = 'UN138',
  CHANGE_RESERVED_CAPACITY_A = 'UN148',
  CHANGE_TARIFF = 'UN139_1',
  CHANGE_RATE_AND_TARIFF = 'UN139_2',
  CHANGE_RATE = 'UN139_3',
  CHANGE_CUSTOMER_DATA = 'UN137'
}

export enum EicSupplementaryInvoiceCategory {
  MATERIAL_NEED_ASSISTANCE = '1',
  VITALLY_INDICATED_PATIENT = '2',
  BLIND_PERSON = '3',
  TEST_OPERATION_RK = '4',
  TEST_OPERATION_POWER_FACTOR = '5'
}

export enum EicDropOffPointType {
  NN_FUSE_BOX_ON_SUPPORT_POINT = '01',
  NN_SWITCHING_AND_PROTECTIVE_DEVICES_IN_DISTRIBUTOR_STATION = '02',
  NN_SWITCHING_AND_PROTECTIVE_DEVICES_IN_PROTECTIVE_BOX = '03',
  BRANCHING_POINT_FROM_DISTRIBUTION_SYSTEM = '04',
  VN_CABLE_TERMINALS_AT_CONSUMER_STATION = '05',
  VN_CABLE_TERMINALS_LOOPED_AT_CONSUMER_STATION = '06',
  VN_ANCHOR_INSULATORS_AT_CONSUMER_STATION = '07',
  VN_BRANCH_TERMINALS_ON_OVERHEAD_VN_LINE = '08',
  VN_OUTPUT_TERMINALS_OF_SWITCHING_ELEMENT = '09',
  STANDARD = '99'
}

export enum EicLowTariffOperatorCode {
  VSD = 'V',
  SSD = 'S',
  ZSD = 'Z'
}
