import React from 'react'
import { Container, Typography, AppPage } from '@otion-core/sandy'

import { ClientFooter, HeaderRightAdornment } from '../'

interface AppPageRouteProps {
  component?: React.FC
  title: string
  headerTitle?: string
  backButtonText: string
  backButtonLocation: string
  subTitle?: string
  renderSmallMenu?: () => React.ReactElement | null
  renderNavigationTabs?: (activeTab: number | undefined) => React.ReactElement | null
  activeTab?: number
  componentProps?: Record<string, unknown>
}

const AppPageRoute = ({
  component,
  title,
  headerTitle,
  backButtonText,
  backButtonLocation,
  subTitle,
  renderSmallMenu,
  renderNavigationTabs,
  activeTab,
  componentProps
}: AppPageRouteProps) => (
  <AppPage
    title={title}
    headerTitle={headerTitle || title}
    headerRightAdornment={<HeaderRightAdornment />}
    backButtonText={backButtonText}
    backButtonLocation={backButtonLocation}
  >
    {subTitle ? (
      <Typography variant='heading' size='large' weight='semibold' style={{ marginBottom: '20px' }}>
        {subTitle}
      </Typography>
    ) : null}

    {renderSmallMenu ? renderSmallMenu() : null}

    {renderNavigationTabs ? renderNavigationTabs(activeTab) : null}

    <Container top={2} bottom={5} fullWidth>
      {component ? React.createElement(component, componentProps) : null}
    </Container>

    <ClientFooter />
  </AppPage>
)

AppPageRoute.displayName = 'AppPageRoute'
export default AppPageRoute
