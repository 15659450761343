import { PromiseAction } from '../../shared/interfaces'
import * as DashboardsApi from '../../apis/dashboards.api'

export const GET_DASHBOARD_ELECTRICITY_TRIGGERED = 'GET_DASHBOARD_ELECTRICITY_TRIGGERED'
export const GET_DASHBOARD_ELECTRICITY_SUCCESS = 'GET_DASHBOARD_ELECTRICITY_SUCCESS'
export const GET_DASHBOARD_ELECTRICITY_FAILURE = 'GET_DASHBOARD_ELECTRICITY_FAILURE'

export const getDashboardElectricity = (): PromiseAction => {
  const promise = DashboardsApi.getDashboardElectricity()
  return {
    onRequest: GET_DASHBOARD_ELECTRICITY_TRIGGERED,
    onSuccess: GET_DASHBOARD_ELECTRICITY_SUCCESS,
    onFailure: GET_DASHBOARD_ELECTRICITY_FAILURE,
    promise
  }
}
