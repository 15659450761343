export interface ISpotPrice {
  time: string
  price: number
}

// eslint-disable-next-line no-shadow
export enum SpotPricesAverageType {
  DAY = 'day',
  HOUR = 'hour'
}

export interface IGetSpotPricesPayload {
  average?: SpotPricesAverageType
  dateFrom?: Date
  dateTo?: Date
}
