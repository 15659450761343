import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge } from '@otion-core/sandy'

import { EXPENSE_TYPE, INCOME_TYPE } from 'src/shared/interfaces'

interface BillSourceProps {
  value?: string
  isExpense: boolean
}

const BillSource = (props: BillSourceProps) => {
  const { t } = useTranslation()

  if (!props.value) return null

  let color
  let name

  switch (props.value) {
    case INCOME_TYPE.INVOICE:
      name = 'INVOICE'
      color = '#BDF0EC'
      break
    case INCOME_TYPE.PROFORMA:
      name = 'PROFORMA'
      color = props.isExpense ? '#FFD1D1' : '#FFD6B9'
      break
    case INCOME_TYPE.ACCEPTED_LOAN:
      name = 'ACCEPTED_LOAN'
      color = '#FBF2C3'
      break
    case INCOME_TYPE.COMMITMENT_PLUS:
      name = 'COMMITMENT_PLUS'
      color = '#CFFCC7'
      break
    case INCOME_TYPE.RECEIVABLE_MINUS:
      name = 'RECEIVABLE_MINUS'
      color = '#CFFCC7'
      break
    case INCOME_TYPE.CAPITAL:
      name = 'CAPITAL'
      color = '#CFFCC7'
      break
    case INCOME_TYPE.OTHER_INCOME:
      name = 'OTHER_INCOME'
      color = '#CFFCC7'
      break
    case EXPENSE_TYPE.COST:
      name = 'COST'
      color = '#BDF0EC'
      break
    case EXPENSE_TYPE.PROFORMA:
      name = 'PROFORMA'
      color = '#FFD6B9'
      break
    case EXPENSE_TYPE.VAT:
      name = 'VAT'
      color = '#FBF2C3'
      break
    case EXPENSE_TYPE.EXCISE_DUTY:
      name = 'EXCISE_DUTY'
      color = '#FBF2C3'
      break
    case EXPENSE_TYPE.OTHER_TAX:
      name = 'OTHER_TAX'
      color = '#FBF2C3'
      break
    case EXPENSE_TYPE.PO_INCOME_TAX:
      name = 'PO_INCOME_TAX'
      color = '#FBF2C3'
      break
    case EXPENSE_TYPE.DIVIDENDS:
      name = 'DIVIDENDS'
      color = '#F3F3F3'
      break
    case EXPENSE_TYPE.COMMITMENT_MINUS:
      name = 'COMMITMENT_MINUS'
      color = '#CFFCC7'
      break
    case EXPENSE_TYPE.RECEIVABLE_PLUS:
      name = 'RECEIVABLE_PLUS'
      color = '#CFFCC7'
      break
    case EXPENSE_TYPE.FINE:
      name = 'FINE'
      color = '#FFD6B9'
      break
    case EXPENSE_TYPE.INTEREST:
      name = 'INTEREST'
      color = '#B4E4FF'
      break
    case EXPENSE_TYPE.WAGES_AND_CONTRIBUTIONS:
      name = 'WAGES_AND_CONTRIBUTIONS'
      color = '#FF7273'
      break
    case EXPENSE_TYPE.OTHER_EXPENSE:
      name = 'OTHER_EXPENSE'
      color = '#F3F3F3'
      break
  }

  return (
    <Badge style={{ background: color, fontWeight: '500' }} color={color}>
      {props.isExpense ? t(`EXPENSE_TYPE.${name}` as never) : t(`INCOME_TYPE.${name}` as never)}
    </Badge>
  )
}

BillSource.displayName = 'BillStatus'
export default BillSource
