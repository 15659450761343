import React from 'react'

import { ReactComponent as LoaderIcon } from './loader.svg'
import * as S from './styles'

interface LoaderProps {
  isSmall?: boolean
}

const Loader = (props: LoaderProps) => {
  return (
    <S.Wrapper className={props.isSmall ? 'small' : ''}>
      <LoaderIcon />
    </S.Wrapper>
  )
}

Loader.displayName = 'Loader'
export default Loader
