import { AnyAction } from 'redux'

import { IWarning, IWarningsState, IWarningType, IPaginationResponse } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IWarningsState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    }
  },
  types: []
}

const warningsReducer = (state = initialState, action: AnyAction): IWarningsState => {
  switch (action.type) {
    case actionTypes.GET_WARNINGS_SUCCESS: {
      const response: IPaginationResponse<IWarning> = action.response?.data

      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length ? state.list.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data }
      }
    }

    case actionTypes.GET_WARNING_TYPES_SUCCESS: {
      const response: IWarningType[] = action.response?.data
      return {
        ...state,
        types: response
      }
    }

    default:
      return state
  }
}

export default warningsReducer
