import React from 'react'
import { Navigate } from 'react-router-dom'

import { removeToken } from '../../utils'

const LogoutPage = () => {
  removeToken()
  return <Navigate to='/login' />
}

LogoutPage.displayName = 'LogoutPage'
export default LogoutPage
