import React, { ReactNode } from 'react'
import { ColorsType, Typography } from '@otion-core/sandy'

import * as S from './styles'

interface Tab {
  tabIndex: number
  icon: ReactNode
  text: string
  name?: string
  disabled?: boolean
}

interface SubCategoryTabProps {
  tabs: Tab[]
  active?: number
  onChange: (index: number) => void
  color: ColorsType
}

const options = {
  loop: false,
  nav: false,
  dots: false,
  margin: 20,
  responsive: {
    0: {
      items: 2.25
    },
    768: {
      items: 2
    },
    992: {
      items: 4
    },
    1200: {
      items: 6
    }
  }
}

const SubCategoryTab = (props: SubCategoryTabProps) => {
  const { tabs, active, color, onChange } = props

  return (
    <React.Fragment>
      <S.Carousel className='owl-theme' {...options}>
        {tabs.map(tab => (
          <S.Toolbox
            backgroundColor={active === tab.tabIndex ? `${color}.light` : 'white'}
            key={tab.tabIndex}
            active={active === tab.tabIndex}
            icon={tab.icon}
            iconColor='black'
            className={typeof tab.icon === 'string' ? 'no-svg-stroke' : ''}
            text={
              <Typography variant='heading' size='small' weight='medium' inline color='black'>
                {tab.text}
              </Typography>
            }
            size='large'
            onClick={() => onChange(tab.tabIndex)}
            disabled={tab.disabled}
          />
        ))}
      </S.Carousel>
    </React.Fragment>
  )
}

SubCategoryTab.displayName = 'SubCategoryTab'
export default SubCategoryTab
